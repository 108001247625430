import { createBrowserRouter } from "react-router-dom";
import { lazy, Suspense } from "react";
import IndexPage from "../Pages/Signin/IndexPage/IndexPage";
// import Homepage from "../Pages/Homepage/Homepage";
import InvestigationGroup from "../Pages/Investigation/InvestigationGroup/InvestigationGoup";
import ProfilePage from "../Pages/ProfilePage/ProfilePage";
import ReportsPage from "../Pages/ReportsPage/ReportsPage";
import Signin from "../Pages/Signin/Signin";
import Signup from "../Pages/Signup/Signup";
import DashboardLayout from "../components/Layout/DashboardLayout";
import PrivateRouter from "./Private/PrivateRouter";
import Balance from "../Pages/Balance/Balance";
import ProfilePreviewPage from "../Pages/ProfilePreview/ProfilePreviewPage";
import Loading from "../utils/Loader/Loading"; // Import your Loading component
import MainLayout from "../components/Layout/MainLayout/MainLayout";
import ScrollToTopOnPageLoad from "../utils/ScrollToTopOnPageLoad/ScrollToTopOnPageLoad";
import VisaPage from "../Pages/VisaPage/VisaPage";
import OfferPage from "../Pages/OfferPage/OfferPage";
import Error from "../utils/Error/Error";
import TeamMemberPage from "../Pages/TeamMemberPage/TeamMemberPage";

const VisaDetailsPage = lazy(() => import('../Pages/VisaDetailsPage/VisaDetailsPage'));
const DestinationDetailsPage = lazy(() => import('../Pages/DestinationDetailsPage/DestinationDetailsPage'));
const TourTypeDetailsPage = lazy(() => import('../Pages/TourTypeDetailsPage/TourTypeDetailsPage'));
const UnderConstructionPage = lazy(() => import('../Pages/UnderConstructionPage/UnderConstructionPage'));
// import TourDetailsPage from "../Pages/TourDetailsPage/TourDetailsPage";
const Homepage = lazy(() => import('../Pages/Homepage/Homepage'));
const TourDetailsPage = lazy(() => import("../components/TourDetailsPage/TourDetailsPage"));
const DestinationPage = lazy(() => import("../Pages/DestinationPage/DestinationPage"));
const ToursPage = lazy(() => import("../Pages/ToursPage/ToursPage"));
const PackageDetailsPage = lazy(() => import("../Pages/PackageDetailsPage/PackageDetailsPage"));
const BlogGridPage = lazy(() => import("../Pages/BlogGridPage/BlogGridPage"));
const BlogDetailsPage = lazy(() => import("../Pages/BlogDetailsPage/BlogDetailsPage"));
const AboutPage = lazy(() => import("../Pages/AboutPage/AboutPage"));
const MessageFromManagement = lazy(() => import("../Pages/MessageFromManagement/MessageFromManagement"));
const ContactPage = lazy(() => import("../Pages/ContactPage/ContactPage"));
const FaqPage = lazy(() => import("../Pages/FaqPage/FaqPage"));
const GalleryPage = lazy(() => import("../Pages/GalleryPage/GalleryPage"));
const Album = lazy(() => import("../Pages/Album/Album"));
const DynamicPage = lazy(() => import('./../Pages/DynamicPage/DynamicPage'));

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   index: true,
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <ScrollToTopOnPageLoad>
  //         <IndexPage />
  //       </ScrollToTopOnPageLoad>
  //     </Suspense>
  //   ),
  // },
  {
    path: "/",
    element: (
      <Suspense fallback={<Loading />}>
        <ScrollToTopOnPageLoad>
          <MainLayout />
        </ScrollToTopOnPageLoad>
      </Suspense>
    ),
    children: [
      {
        path: "/",
        index: true,
        element: <IndexPage />,
        // element: <Homepage />,
      },
      {
        path: "home",
        index: true,
        element: <IndexPage />,
        // element: <Homepage />,
      },
      {
        path: "multistepform",
        index: true,
        element: <Homepage />,
      },
      {
        path: "tours",
        element: <ToursPage />,
        // element: <div>Tours</div>,
      },
      {
        path: "package",
        element: <ToursPage />,
        // element: <div>Tours</div>,
      },
      {
        path: "destination",
        element: <DestinationPage />,
        // element: <UnderConstructionPage />,
      },
      {
        path: "destination/:id",
        element: <DestinationDetailsPage />,
        // element: <UnderConstructionPage />,

      },
      {
        path: "package/:id",
        element: <PackageDetailsPage />,
        // element: <div>Hi</div>,
      },
      {
        path: "tourtype/:id",
        element: <TourTypeDetailsPage />,
      },
      {
        path: "blog",
        element: <BlogGridPage />,
      },
      {
        path: "blog/:id",
        element: <BlogDetailsPage />,
      },
      {
        path: "visa",
        element: <VisaPage />,
      },
      {
        path: "offer",
        element: <OfferPage />,
      },
      {
        path: "visa/:id",
        element: <VisaDetailsPage />,
      },
      {
        path: "team-member",
        element: <TeamMemberPage />,
      },
      {
        path: "gallery",
        element: <Album />,
        // element: <UnderConstructionPage />,
      },
      {
        path: "media-gallery",
        element: <Album />,
        // element: <UnderConstructionPage />,
      },
      {
        path: "gallery/:id",
        element: <GalleryPage />,
        // element: <UnderConstructionPage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "message-from-management",
        element: <MessageFromManagement />,
      },
      {
        path: "contact",
        element: <ContactPage />,
      },
      // {
      //   path: "faq",
      //   element: <FaqPage />,
      // },
      {
        path: "tour-details/:id",
        element: <TourDetailsPage />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "reports",
        element: <ReportsPage />,
      },
      {
        path: "balance",
        element: <Balance />,
      },
      {
        path: "profile-preview",
        element: <ProfilePreviewPage />,
      },
      {
        path: "*",
        element: <DynamicPage />,
      },
      // {
      //   path: "*",
      //   element: <div>This is Under Constraction</div>,
      //   // element: <DynamicPage />,
      //   // handle: { title: "Contact Us", description: "GoTrip" },
      // },
    ],
  },
  {
    path: "/error",
    element: (
      <Suspense fallback={<Loading />}>
        <Error />
      </Suspense>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <Suspense fallback={<Loading />}>
        <DashboardLayout />
      </Suspense>
    ),
    children: [
      {
        path: "all-tour",
        element: <ToursPage />,
      },
    ],
  },
  {
    path: "/coming-soon",
    element: (
      <Suspense fallback={<Loading />}>
        <UnderConstructionPage />
      </Suspense>
    ),
  },
  {
    path: "/error",
    element: (
      <Suspense fallback={<Loading />}>
        <Error />
      </Suspense>
    ),
  },
  // Add more routes as needed
]);

export default router;
