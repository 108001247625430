import React from 'react';
import { Link } from 'react-router-dom';
import { img_url } from '../../utils/mainurl/mainurl';
import LoadingSm from '../../utils/Loader/LoadingSm';
import useFetchData from '../../hooks/useFetch/useFetchData';
import './OfferCard.css'

const OfferCard = ({ one }) => {

    const { data: settingsRes, isLoading: isLoadingSettings, isError: isErrorSettings } = useFetchData(
        'settings',
        `settings`
    );

    // const { currency, vat_type } = settingsRes?.data[0]

    if (isLoadingSettings) return <div className=""><LoadingSm /></div>;

    return (
        <div className="swiper-slide"
            style={{
                maxWidth: '358px',
                maxHeight: '326px', // lg Screen
                minWidth: '100%',
                minHeight: '100%', // lg Screen
                // width: '372px', height: '258px', // mobile
                objectFit: 'cover'
                // Ensures the image covers the entire area without distortion 
            }}
        >
            <div className="package-card2">
                <Link to={one?.button_link}>
                    <img
                        src={`${img_url}${one?.image}`}
                        alt={`${img_url}${one?.image}`}
                        // style={{
                        //     // maxWidth: '358px',
                        //     // maxHeight: '326px', // lg Screen
                        //     // minWidth: '100%',
                        //     // minHeight: '100%', // lg Screen
                        //     minWidth: '358px',
                        //     minHeight: '326px', // lg Screen
                        //     maxWidth: '100%',
                        //     maxHeight: '326px', // lg Screen
                        //     // width: '372px', height: '258px', // mobile
                        //     objectFit: 'cover'
                        //     // Ensures the image covers the entire area without distortion 
                        // }}
                        className='image-style-offer'
                    />
                </Link>
                <div className="eg-tag offer-card-style-color">
                    <h4 className='offer-btn-text'>
                        {
                            one?.button_text ?
                                <Link to={one?.button_link}><div className='offer-btn-text'>{one?.button_text}</div></Link> :
                                <Link to={one?.button_link}><div className='offer-btn-text'>Offer</div></Link>
                        }
                    </h4>
                </div>
                <div className="package-card2-content offer-card-style-color">
                    <div className="title">
                        <h6>{one?.title}</h6>
                    </div>
                    <div className="price-area">
                        <span>{one?.offer_percentage}%</span>
                        <p>{one?.sub_title}</p>
                        {/* {
                            settingsRes?.data[0]?.vat_type === 1 ? 
                            } */}
                        {/* <p>TAXES INCL/PERS</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfferCard;