import React from 'react';
import { Link } from 'react-router-dom';
import { img_url } from '../../utils/mainurl/mainurl';
import LoadingSm from '../../utils/Loader/LoadingSm';
import useFetchData from '../../hooks/useFetch/useFetchData';
import './VisaCard.css'

const VisaCard  = ({ visaOne }) => {

    const { data: settingsRes, isLoading: isLoadingSettings, isError: isErrorSettings } = useFetchData(
        'settings',
        `settings`
    );

    // const { currency, vat_type } = settingsRes?.data[0]

    if (isLoadingSettings) return <div className=""><LoadingSm /></div>;

    return (
        <div className="swiper-slide"
            style={{
                maxWidth: '358px',
                maxHeight: '326px', // lg Screen
                minWidth: '100%',
                minHeight: '100%', // lg Screen
                // width: '372px', height: '258px', // mobile
                objectFit: 'cover'
                // Ensures the image covers the entire area without distortion 
            }}
        >
            <div className="package-card2">
                <Link to={`/visa/${visaOne?.id}`}>
                    <img
                        src={`${img_url}${visaOne?.image}`}
                        alt={`${img_url}${visaOne?.image}`}
                        // style={{
                        //     // maxWidth: '358px',
                        //     // maxHeight: '326px', // lg Screen
                        //     // minWidth: '100%',
                        //     // minHeight: '100%', // lg Screen
                        //     minWidth: '358px',
                        //     minHeight: '326px', // lg Screen
                        //     maxWidth: '100%',
                        //     maxHeight: '326px', // lg Screen
                        //     // width: '372px', height: '258px', // mobile
                        //     objectFit: 'cover'
                        //     // Ensures the image covers the entire area without distortion 
                        // }}
                        className='image-style-visa'
                    />
                </Link>
                {/* <div className="eg-tag">
                    <h4>
                        <Link to={`/visa/${visaOne?.id}`}><div>Visa</div></Link>
                    </h4>
                </div> */}
                <div className="package-card2-content">
                    <div className="title">
                        <h6>{visaOne?.name}</h6>
                    </div>
                    <div className="price-area">
                        <span>{settingsRes?.data[0]?.currency}{visaOne?.price}</span>
                        {
                            settingsRes?.data[0]?.vat_type === 1 ? <p>TAXES INCL/PERS</p> : <p>NO TAXES</p>
                        }
                        {/* <p>TAXES INCL/PERS</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisaCard;