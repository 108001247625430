import { FormWrapper } from "./FormWrapper";

export function UserForm({
  name,
  phone,
  email,
  updateFields,
}) {
  return (
    <FormWrapper title="User Details">
      <div>
        <label htmlFor="name" className="form-label">Name</label>
        <input
          id="name"
          autoFocus
          
          type="text"
          value={name}
          placeholder="Enter your name"
          className="form-control"
          onChange={(e) => updateFields({ name: e.target.value })}
        />
      </div>

      <div>
        <label htmlFor="phone" className="form-label">Phone</label>
        <input
          id="phone"
          required
          type="text"
          value={phone}
          placeholder="Enter your phone"
          className="form-control"
          onChange={(e) => updateFields({ phone: e.target.value })}
        />
      </div>

      <div>
        <label htmlFor="email" className="form-label">Email</label>
        <input
          id="email"
          
          type="email"
          value={email}
          placeholder="Enter your email"
          className="form-control mb-3"
          onChange={(e) => updateFields({ email: e.target.value })}
        />
      </div>
    </FormWrapper>
  );
}
