import { FormWrapper } from "./FormWrapper";

export function BudgetForm({
    budget,
    updateFields,
}) {
    const budgetRanges = [
        "5001 - 10000",
        "10001 - 15000",
        "15001 - 20000",
        "20001 - 30000",
        "30001 - 40000",
        "40001 - 60000",
    ];

    return (
        <FormWrapper title="Select a Range">
            <div className="row">
                {budgetRanges.map(one => (
                    <div className="form-check" key={one}>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="budget"
                            id={`budget-${one}`}
                            value={one}
                            checked={budget === one} // ✅ Maintain selection
                            onChange={e => updateFields({ budget: e.target.value })}
                        />
                        <label className="form-check-label" htmlFor={`budget-${one}`}>
                            {one}
                        </label>
                    </div>
                ))}

            </div>
        </FormWrapper>
    );
}


// export function MonthPickerForm({
//     selectedMonth,
//     updateFields,
// }) {
//     const months = [
//         "January", "February", "March", "April", "May", "June",
//         "July", "August", "September", "October", "November", "December"
//     ];

//     return (
//         <FormWrapper title="Select a Month">
//             <div className="d-flex flex-wrap gap-3">
//                 {months.map(month => (
//                     <div className="form-check" key={month}>
//                         <input
//                             className="form-check-input"
//                             type="radio"
//                             name="selectedMonth"
//                             id={`month-${month}`}
//                             value={month}
//                             checked={selectedMonth === month} // ✅ Maintain selection
//                             onChange={e => updateFields({ selectedMonth: e.target.value })}
//                         />
//                         <label className="form-check-label" htmlFor={`month-${month}`}>
//                             {month}
//                         </label>
//                     </div>
//                 ))}
//             </div>
//         </FormWrapper>
//     );
// }

