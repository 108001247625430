import useFetchData from "../../hooks/useFetch/useFetchData";
import LoadingSm from "../../utils/Loader/LoadingSm";
import { FormWrapper } from "./FormWrapper"

export function TravelcategoryForm({
    travelcategory,
    updateFields,
    // firstName,
    // lastName,
    // age,
}) {
    const { data: travelCategoryRes, isLoadingTravelCategory, isErrorTravelCategory } = useFetchData('travelCategory', 'travelCategory');

    const travelCategorys = travelCategoryRes?.data;
    console.log("tourTypes", travelCategorys)

    if (isLoadingTravelCategory) return (<LoadingSm />)
    return (
        <FormWrapper title="Travel Category">
            {
                travelCategorys?.map((onee) => <>
                    <div class="form-check">
                        <input class="form-check-input" type="radio"
                            // name={`flexRadioDefault-${onee.id}`} 
                            // name="flexRadioDefault1" 
                            name="travelcategory" // All radios should share the same name
                            id={`flexRadioDefault-${onee.id}`}
                            checked={travelcategory === onee.name}  // ✅ Maintain selection
                            value={onee.name}
                            onChange={e => updateFields({ travelcategory: e.target.value })}
                        />
                        <label class="form-check-label"
                            // for="flexRadioDefault1"
                            htmlFor={`flexRadioDefault-${onee.id}`}
                        >
                            {onee?.name}
                        </label>
                    </div>
                </>)
            }
        </FormWrapper>
    )
}
