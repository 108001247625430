import React from 'react';
import { Link } from 'react-router-dom';
import './TourTypeCard.css'
import { img_url } from '../../utils/mainurl/mainurl';

const TourTypeCard = ({ data }) => {
    return (
        <div className="destination-card3">
            <Link to={`/tourtype/${data?.id}`} className="destination-card-img">
                {/* <img src="assets/img/home5/destination-card3-img1.jpg" alt="" /> */}
                <img src={`${img_url}${data?.image}`}
                    style={{
                        maxWidth: '318px',
                        maxHeight: '223px', // lg Screen
                        minWidth: '100%',
                        minHeight: '100%', // lg Screen
                        // width: '372px', height: '258px', // mobile
                        objectFit: 'cover'
                        // Ensures the image covers the entire area without distortion 
                    }}
                    // style={{
                    //     width: '262px', height: '245px', objectFit: 'cover'
                    //     // Ensures the image covers the entire area without distortion 
                    // }}
                    alt=""
                    className='tourtype-card-image'
                />
            </Link>
            <div className="destination-card-content"

            >
                <h4>
                    <Link to={`/tourtype/${data?.id}`}
                        style={{ textDecoration: 'none' }}
                    >{data?.name}</Link>
                </h4>
            </div>
            {
                <div className="batch">
                    <span>{
                        data?.package?.length || "No"
                    } Tour</span>
                </div>
            }

        </div>
    );
};

export default TourTypeCard;