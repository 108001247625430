import { FormWrapper } from "./FormWrapper";

export function MonthPickerForm({
    selectedMonth,
    updateFields,
}) {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return (
        <FormWrapper title="Select a Month">
            <div className="row">
                {[0, 1].map(col => (
                    <div key={col} className="col-md-6">
                        {months.slice(col * 6, col * 6 + 6).map(month => (
                            <div className="form-check" key={month}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="selectedMonth"
                                    id={`month-${month}`}
                                    value={month}
                                    checked={selectedMonth === month} // ✅ Maintain selection
                                    onChange={e => updateFields({ selectedMonth: e.target.value })}
                                />
                                <label className="form-check-label" htmlFor={`month-${month}`}>
                                    {month}
                                </label>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </FormWrapper>
    );
}


// export function MonthPickerForm({
//     selectedMonth,
//     updateFields,
// }) {
//     const months = [
//         "January", "February", "March", "April", "May", "June",
//         "July", "August", "September", "October", "November", "December"
//     ];

//     return (
//         <FormWrapper title="Select a Month">
//             <div className="d-flex flex-wrap gap-3">
//                 {months.map(month => (
//                     <div className="form-check" key={month}>
//                         <input
//                             className="form-check-input"
//                             type="radio"
//                             name="selectedMonth"
//                             id={`month-${month}`}
//                             value={month}
//                             checked={selectedMonth === month} // ✅ Maintain selection
//                             onChange={e => updateFields({ selectedMonth: e.target.value })}
//                         />
//                         <label className="form-check-label" htmlFor={`month-${month}`}>
//                             {month}
//                         </label>
//                     </div>
//                 ))}
//             </div>
//         </FormWrapper>
//     );
// }

