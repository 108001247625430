import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { format } from "date-fns";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AgencySummaryModal from "../../../components/Modal/RegistrationSummaryModal/AgencySummaryModal";
import CountrySummaryModal from "../../../components/Modal/RegistrationSummaryModal/CountrySummaryModal";
import PackageSummaryModal from "../../../components/Modal/RegistrationSummaryModal/PackageSummaryModal";
import RepresentativeSummeryModal from "../../../components/Modal/RegistrationSummaryModal/RepresentativeSummaryModal";
import "./MedicalReports.scss";
import { UserContext } from "../../../Context/UserContextAPI";
import toast from "react-hot-toast";
import Select from "react-select";
import VirtualizedMenuList from "../../../components/VirtualMenuList/VirtualMenuList";
import VirtualizedMenuListPackage from "../../../components/VirtualMenuList/VirtualMenuListPackage";
import Loading from "../../../utils/Loader/Loading";

const MedicalReportsCopy = ({ roleId, userId }) => {
  const { smsAPI, introducerUsers, packages, accessPerm } =
    useContext(UserContext);
  const location = useLocation();
  const [userDetails, setUserDetails] = useState([]);
  const [regInputField, setRegInputField] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusCounts, setStatusCounts] = useState({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });
  const [totalStatus, setTotalStatus] = useState(0);
  const [otherStatus, setOtherStatus] = useState(0);

  const [countryWiseUsers, setCountryWiseUsers] = useState([]);
  const [introducerWiseUsers, setIntroducerWiseUsers] = useState([]);
  const [packageWiseUsers, setPackageWiseUsers] = useState([]);
  const [representativeWiseUsers, setRepresentativeWiseUsers] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    package: null,
    introducer: null,
    country: null,
    company: null,
    delegate: null,
  });

  const options = [
    { value: "", label: "Select Introducer" },
    ...introducerUsers.map((user) => {
      const representativeMobile =
        user?.user_users_maps[0]?.representative_user_map?.mobile;

      const mobileLabel = representativeMobile
        ? `(${representativeMobile.slice(-2)})`
        : "(Not Assigned)";

      return {
        value: parseInt(user?.id),
        label: `${user?.registration_no} - ${user?.f_name} - ${mobileLabel}`,
      };
    }),
  ];

  const packageOptions = [
    { value: "", label: "Select Package" },
    ...packages?.map((pack) => ({
      value: parseInt(pack?.id),
      label: `${pack?.name}`,
    })),
  ];

  const calculateStatus = (data) => {
    const counts = {
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
    };
    let notDoneCount = 0;

    data.forEach((userInfo) => {
      const collection = userInfo?.user?.user_collections?.map(
        (collection) =>
          collection.investigation_category?.investigation_group_id
      );

      const uniqueCollection = [...new Set(collection)];
      const done =
        (uniqueCollection?.length < 2 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) <
            2) ||
        (uniqueCollection?.length >= 2 &&
          uniqueCollection?.length < 3 &&
          (userInfo?.user?.is_pathology_done ? 1 : 0) +
            (userInfo?.user?.is_physical_done ? 1 : 0) +
            (userInfo?.user?.is_radiology_done ? 1 : 0) >=
            2) ||
        (uniqueCollection?.length >= 3 &&
          userInfo?.user?.is_pathology_done &&
          userInfo?.user?.is_physical_done &&
          userInfo?.user?.is_radiology_done);

      // Counting entries where the task is not done
      if (!done || userInfo?.user?.status === 1) {
        notDoneCount++;
      }

      switch (userInfo?.user?.status) {
        case 1:
          counts.status1 = counts.status1 + 1;
          break;
        case done && 2:
          counts.status2 = counts.status2 + 1;
          break;
        case done && 3:
          counts.status3 = counts.status3 + 1;
          break;
        case done && 4:
          counts.status4 = counts.status4 + 1;
          break;
        default:
          break;
      }
    });
    setStatusCounts(counts);
    setTotalStatus(userDetails?.length);
    setOtherStatus(notDoneCount);
  };

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const status = form.status.value;
    const packageId = form.package.value;
    const introducer = roleId === 8 ? userId : form.introducer.value;
    const country = "";
    const company = "";
    const delegate = "";
    const regNo = form.invoice_reg_mobile.value;

    const filterData = {
      fromDate,
      toDate,
      status,
      packageId,
      introducer,
      country,
      company,
      delegate,
      regNo: regNo.trim(),
    };
    methodFilterPatientsDetails(filterData, "form");
  };

  const handlerLocationSearch = (e) => {
    const regNo = location?.state;
    const filterData = {
      regNo,
    };
    methodFilterPatientsDetails(filterData, "form");
  };

  // Get Specific date users-------
  const methodFilterPatientsDetails = async (filterData, form) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user-details/search`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
          },
        }
      );

      const data = response?.data;
      if (data) {
        calculateStatus(data?.userDetails);

        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setTotalStatus(data?.userDetails?.length);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
    const ref = useRef(null);

    useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + " cursor-pointer"}
        {...rest}
      />
    );
  }

  const handleSelect = (name, selectedOptions) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: selectedOptions,
    }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 2:
        return "text-success";
      case 3:
        return "text-danger";
      case 4:
        return "text-primary";
      default:
        return "";
    }
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("SL.", {
        header: ({ table }) => (
          <div>
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        ),
        cell: ({ row }) => {
          return (
            <div>
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("SL.", {
        header: () => (
          <div className="d-flex justify-content-center">
            <span className="text-center px-2">SL</span>
          </div>
        ),
        cell: (info) => {
          const reversedSerialNumber = userDetails?.length - info?.row?.id;
          return (
            <div className="text-start d-flex justify-content-start fw-bold">
              <span className="text-start">{reversedSerialNumber}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: () => (
          <span className="d-flex justify-content-center">Date</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          const dateValue = new Date(info.renderValue(reversedIndex));
          const formattedDate = format(dateValue, "dd-MM-yyyy");

          return (
            <div className="d-flex justify-content-start">{formattedDate}</div>
          );
        },
      }),

      columnHelper.accessor("user.registration_no", {
        header: () => (
          <span className="d-flex justify-content-center">Reg. No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.reg_prefix || ""}
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Patient Name</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.passport_no", {
        header: () => (
          <span className="d-flex justify-content-center">Passport No</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("introducer_user.f_name", {
        header: () => (
          <span className="d-flex justify-content-center">Agency</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;

          info.getValue();
          const value1 = info.renderValue(reversedIndex);
          const representative_Mob =
            info?.row?.original?.introducer_user?.user_users_maps[0]?.representative_user_map?.mobile?.slice(
              -2
            );
          return (
            <div>
              <span className="d-flex justify-content-start">
                {representative_Mob ? (
                  <span className="text-left d-flex justify-content-start">
                    {value1} - ({representative_Mob})
                  </span>
                ) : (
                  <span className="text-left d-flex justify-content-start">
                    {value1}
                  </span>
                )}
              </span>
            </div>
          );
        },
      }),

      columnHelper.accessor("country.shortname", {
        header: () => (
          <span className="d-flex justify-content-center">Country</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("package.name", {
        header: () => (
          <span className="d-flex justify-content-center">Package</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info?.row?.original?.user?.role_id !== 5
                ? info.renderValue(reversedIndex)
                : "Individual Test"}
            </div>
          );
        },
      }),

      columnHelper.accessor("user.role.name", {
        header: () => (
          <span className="d-flex justify-content-center">Type</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          return (
            <div className="d-flex justify-content-start">
              {info.renderValue(reversedIndex)}
            </div>
          );
        },
      }),

      columnHelper.accessor("user", {
        header: () => (
          <span className="d-flex justify-content-center">Status</span>
        ),
        cell: (info, index) => {
          const reversedIndex = userDetails.length - index - 1;
          const {
            is_radiology_done,
            is_pathology_done,
            is_physical_done,
            status,
          } = info.renderValue(reversedIndex);

          const collection = info?.row?.original?.user?.user_collections?.map(
            (collection) =>
              collection.investigation_category?.investigation_group_id
          );

          const uniqueCollection = [...new Set(collection)];

          const done =
            (uniqueCollection?.length < 2 &&
              (is_pathology_done || is_physical_done || is_radiology_done)) ||
            (uniqueCollection?.length >= 2 &&
              uniqueCollection?.length < 3 &&
              (is_pathology_done ? 1 : 0) +
                (is_physical_done ? 1 : 0) +
                (is_radiology_done ? 1 : 0) >=
                2) ||
            (uniqueCollection?.length >= 3 &&
              is_pathology_done &&
              is_physical_done &&
              is_radiology_done);
          let content;

          if (done && status === 2) {
            content = "FIT";
          } else if (done && status === 3) {
            content = "UNFIT";
          } else if (done && status === 4) {
            content = "HELD-UP";
          } else {
            content = "PROCESSING";
          }

          return <div className="d-flex justify-content-start">{content}</div>;
        },
      }),

      // Conditionally render the Actions column
      ...(roleId !== 8
        ? [
            columnHelper.accessor("user", {
              header: () => (
                <span className="d-flex justify-content-center">Actions</span>
              ),
              cell: (info, index) => {
                let unique = [];
                let track = false;
                const reversedIndex = userDetails.length - index - 1;
                const collection = info
                  .renderValue()
                  ?.user_collections?.map(
                    (collection) =>
                      collection.investigation_category?.investigation_group_id
                  );
                const uniqueCollection = [...new Set(collection)];

                for (
                  let i = 0;
                  i < info.renderValue().user_collections?.length;
                  i++
                ) {
                  if (
                    track &&
                    (info.renderValue().user_collections[i]
                      .investigation_category_id === 4 ||
                      info.renderValue().user_collections[i]
                        .investigation_category_id === 5)
                  )
                    continue;
                  if (
                    info.renderValue().user_collections[i]
                      .investigation_category_id === 4 ||
                    info.renderValue().user_collections[i]
                      .investigation_category_id === 5
                  ) {
                    track = true;
                  }
                  unique.push(info.renderValue().user_collections[i]);
                }
                const isPathologyDone = info?.renderValue()?.is_pathology_done;
                const isPhysicalDone = info?.renderValue()?.is_physical_done;
                const isRadiologyDone = info?.renderValue()?.is_radiology_done;
                const xrayUploaded =
                  info.renderValue()?.radiology_tests[0]?.user_file;
                const uniqueNew = unique.reduce((acc, current) => {
                  const x = acc.find(
                    (item) =>
                      item.investigation_category.investigation_group_id ===
                      current.investigation_category.investigation_group_id
                  );
                  if (!x) {
                    acc.push(current);
                  }
                  return acc;
                }, []);
                //check unique and uniqueNew
                console.log(unique);
                return (
                  <div className="d-grid gap-1 d-grid-iocns">
                    {unique?.length > 0 && accessPerm(33, 5) && (
                      <div className="">
                        {unique.length < 2 ? (
                          <Link
                            to={`/dashboard/medical-report/${
                              info.getValue().id
                            }`}
                            className={`action-icon ${getStatusColor(
                              info.renderValue().status
                            )}`}
                            target="_blank"
                          >
                            <i
                              className={`fa-solid fa-eye fs-6 ${getStatusColor(
                                info.renderValue().status
                              )}`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Preview"
                            ></i>
                          </Link>
                        ) : unique.length >= 2 && unique.length < 3 ? (
                          <Link
                            to={`/dashboard/medical-report/${
                              info.getValue().id
                            }`}
                            className={`action-icon ${getStatusColor(
                              info.renderValue().status
                            )}`}
                            target="_blank"
                          >
                            <i
                              className={`fa-solid fa-eye fs-6 ${getStatusColor(
                                info.renderValue().status
                              )}`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Preview"
                            ></i>
                          </Link>
                        ) : unique.length >= 3 ? (
                          <Link
                            to={`/dashboard/medical-report/${
                              info.getValue().id
                            }`}
                            className={`action-icon ${getStatusColor(
                              info.renderValue().status
                            )}`}
                            target="_blank"
                          >
                            <i
                              className={`fa-solid fa-eye fs-6 ${getStatusColor(
                                info.renderValue().status
                              )}`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Preview"
                            ></i>
                          </Link>
                        ) : null}
                      </div>
                    )}
                    {uniqueNew?.map(
                      (
                        { investigation_category: { investigation_group_id } },
                        i
                      ) => {
                        switch (investigation_group_id) {
                          case 1:
                            return (
                              accessPerm(33, 14) && (
                                <Link
                                  to={{
                                    pathname:
                                      isPathologyDone === 1 &&
                                      accessPerm(33, 11)
                                        ? `/dashboard/medical-reports/pathology/${
                                            info.getValue().id
                                          }`
                                        : isPathologyDone !== 1
                                        ? `/dashboard/medical-reports/pathology/${
                                            info.getValue().id
                                          }`
                                        : "",
                                    search: "?data=1",
                                  }}
                                  key={i}
                                  className={`action-icon pathology-icon`}
                                  style={{
                                    cursor:
                                      isPathologyDone === 1 &&
                                      !accessPerm(33, 11)
                                        ? "not-allowed"
                                        : "pointer",
                                    padding: "0",
                                    width: "0",
                                  }}
                                  target="_blank"
                                  onClick={(event) => {
                                    if (
                                      isPathologyDone === 1 &&
                                      !accessPerm(33, 11)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                >
                                  <i
                                    className={`fa-solid fa-vial fs-6 ${
                                      isPathologyDone === 1
                                        ? "text-success"
                                        : ""
                                    }`}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Pathology"
                                  ></i>
                                </Link>
                              )
                            );
                          case 2:
                            return (
                              accessPerm(33, 13) && (
                                <Link
                                  to={{
                                    pathname:
                                      isRadiologyDone === 1 &&
                                      accessPerm(33, 11)
                                        ? `/dashboard/medical-reports/radiology/${
                                            info.getValue().id
                                          }`
                                        : isRadiologyDone !== 1
                                        ? `/dashboard/medical-reports/radiology/${
                                            info.getValue().id
                                          }`
                                        : "",
                                    search: "?data=2",
                                  }}
                                  key={i}
                                  className={`action-icon radiology-icon`}
                                  style={{
                                    cursor:
                                      isRadiologyDone === 1 &&
                                      !accessPerm(33, 11)
                                        ? "not-allowed"
                                        : "pointer",
                                    padding: "0",
                                    width: "0",
                                  }}
                                  target="_blank"
                                  onClick={(event) => {
                                    if (
                                      isRadiologyDone === 1 &&
                                      !accessPerm(33, 11)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                >
                                  <i
                                    className={`fa-solid fa-x-ray fs-6 ${
                                      isRadiologyDone === 1 && xrayUploaded
                                        ? "text-success"
                                        : isRadiologyDone === 0 && xrayUploaded
                                        ? "text-warning"
                                        : isRadiologyDone === 0 && !xrayUploaded
                                        ? "text-dark"
                                        : "text-success"
                                    }`}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Radiology"
                                  ></i>
                                </Link>
                              )
                            );
                          case 3:
                            return (
                              accessPerm(33, 15) && (
                                <Link
                                  key={i}
                                  to={{
                                    pathname:
                                      isPhysicalDone === 1 && accessPerm(33, 11)
                                        ? `/dashboard/medical-reports/physical/${
                                            info.getValue().id
                                          }`
                                        : isPhysicalDone !== 1
                                        ? `/dashboard/medical-reports/physical/${
                                            info.getValue().id
                                          }`
                                        : "",
                                    search: "?data=3",
                                  }}
                                  className={`action-icon physical-icon`}
                                  style={{
                                    cursor:
                                      isPhysicalDone === 1 &&
                                      !accessPerm(33, 11)
                                        ? "not-allowed"
                                        : "pointer",
                                    padding: "0",
                                    width: "0",
                                  }}
                                  target="_blank"
                                  onClick={(event) => {
                                    if (
                                      isPhysicalDone === 1 &&
                                      !accessPerm(33, 11)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                >
                                  <i
                                    className={`fa-solid fa-street-view fs-6 ${
                                      isPhysicalDone === 1 ? "text-success" : ""
                                    }`}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Physical"
                                  ></i>
                                </Link>
                              )
                            );
                          default:
                            return null;
                        }
                      }
                    )}
                    {/* Add other actions based on status */}
                    {accessPerm(33, 10) &&
                      (info.renderValue(reversedIndex).status === 2 &&
                      ((uniqueCollection?.length < 2 &&
                        (isPathologyDone ||
                          isPhysicalDone ||
                          isRadiologyDone)) ||
                        (uniqueCollection?.length >= 2 &&
                          uniqueCollection?.length < 3 &&
                          (isPathologyDone ? 1 : 0) +
                            (isPhysicalDone ? 1 : 0) +
                            (isRadiologyDone ? 1 : 0) >=
                            2) ||
                        (uniqueCollection?.length >= 3 &&
                          isPathologyDone &&
                          isPhysicalDone &&
                          isRadiologyDone)) ? (
                        <Link
                          to={`/dashboard/healthcard/${info.renderValue().id}`}
                          className="action-icon prescription-icon"
                          state={info.renderValue()}
                          style={{
                            cursor: "pointer",
                            padding: "0",
                            width: "0",
                          }}
                          target="_blank"
                        >
                          <i
                            className="fa-regular fa-address-card fs-6 text-success"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Health Card"
                          ></i>
                        </Link>
                      ) : null)}
                    {info.renderValue(reversedIndex).status === 4 &&
                    ((uniqueCollection?.length < 2 &&
                      (isPathologyDone || isPhysicalDone || isRadiologyDone)) ||
                      (uniqueCollection?.length >= 2 &&
                        uniqueCollection?.length < 3 &&
                        (isPathologyDone ? 1 : 0) +
                          (isPhysicalDone ? 1 : 0) +
                          (isRadiologyDone ? 1 : 0) >=
                          2) ||
                      (uniqueCollection?.length >= 3 &&
                        isPathologyDone &&
                        isPhysicalDone &&
                        isRadiologyDone)) ? (
                      <>
                        {accessPerm(33, 12) && (
                          <Link
                            to={
                              info?.row?.original?.user?.user_pescriptions[0]
                                ?.user_id && accessPerm(33, 11)
                                ? `/dashboard/prescription/${
                                    info.getValue().id
                                  }`
                                : !info?.row?.original?.user
                                    ?.user_pescriptions[0]?.user_id
                                ? `/dashboard/prescription/${
                                    info.getValue().id
                                  }`
                                : ""
                            }
                            className={`action-icon prescription-icon`}
                            style={{
                              cursor:
                                info?.row?.original?.user?.user_pescriptions[0]
                                  ?.user_id && !accessPerm(33, 11)
                                  ? "not-allowed"
                                  : "pointer",
                              padding: "2px",
                              width: "0",
                              color: info?.row?.original?.user
                                ?.user_pescriptions[0]?.user_id
                                ? "#5cb85c"
                                : "#000",
                            }}
                            target="_blank"
                            onClick={(event) => {
                              if (
                                info?.row?.original?.user?.user_pescriptions[0]
                                  ?.user_id &&
                                !accessPerm(33, 11)
                              ) {
                                event.preventDefault();
                              }
                            }}
                          >
                            <i
                              className="fa-solid fa-prescription"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Prescription"
                            ></i>
                          </Link>
                        )}
                        {accessPerm(33, 16) && (
                          <Link
                            to={`/dashboard/held-up-certificate/${
                              info.getValue().id
                            }`}
                            className="action-icon prescription-icon text-primary"
                            style={{
                              cursor: "pointer",
                              padding: "0",
                              width: "0",
                            }}
                            target="_blank"
                          >
                            <i
                              className="bi bi-patch-exclamation-fill fs-6"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Held Up Certificate"
                            ></i>
                          </Link>
                        )}
                      </>
                    ) : null}
                    {accessPerm(33, 16) &&
                      (info.renderValue(reversedIndex).status === 2 &&
                      ((uniqueCollection?.length < 2 &&
                        (isPathologyDone ||
                          isPhysicalDone ||
                          isRadiologyDone)) ||
                        (uniqueCollection?.length >= 2 &&
                          uniqueCollection?.length < 3 &&
                          (isPathologyDone ? 1 : 0) +
                            (isPhysicalDone ? 1 : 0) +
                            (isRadiologyDone ? 1 : 0) >=
                            2) ||
                        (uniqueCollection?.length >= 3 &&
                          isPathologyDone &&
                          isPhysicalDone &&
                          isRadiologyDone)) ? (
                        <Link
                          to={`/dashboard/fit-certificate/${
                            info.getValue().id
                          }`}
                          className="action-icon prescription-icon text-success"
                          style={{
                            cursor: "pointer",
                            padding: "0",
                            width: "0",
                          }}
                          target="_blank"
                        >
                          <i
                            className="bi bi-file-medical fs-6"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Fit Certificate"
                          ></i>
                        </Link>
                      ) : null)}
                    {accessPerm(33, 16) &&
                      (info.renderValue(reversedIndex).status === 3 &&
                      ((uniqueCollection?.length < 2 &&
                        (isPathologyDone ||
                          isPhysicalDone ||
                          isRadiologyDone)) ||
                        (uniqueCollection?.length >= 2 &&
                          uniqueCollection?.length < 3 &&
                          (isPathologyDone ? 1 : 0) +
                            (isPhysicalDone ? 1 : 0) +
                            (isRadiologyDone ? 1 : 0) >=
                            2) ||
                        (uniqueCollection?.length >= 3 &&
                          isPathologyDone &&
                          isPhysicalDone &&
                          isRadiologyDone)) ? (
                        <Link
                          to={`/dashboard/unfit-certificate/${
                            info.getValue().id
                          }`}
                          className="action-icon prescription-icon text-danger"
                          style={{
                            cursor: "pointer",
                            padding: "0",
                            width: "0",
                          }}
                          target="_blank"
                        >
                          <i
                            className="fa-regular fa-file-lines fs-6"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Unfit Certificate"
                          ></i>
                        </Link>
                      ) : null)}
                    <Link
                      to={`/dashboard/entry/${info.renderValue().id}`}
                      className="action-icon prescription-icon text-dark"
                      state={info.renderValue()}
                      style={{
                        cursor: "pointer",
                        padding: "0",
                        width: "0",
                      }}
                      target="_blank"
                    >
                      <i
                        className={`bi bi-card-text fs-6 ${
                          isRadiologyDone === 1 && xrayUploaded
                            ? "text-success"
                            : isRadiologyDone === 0 && xrayUploaded
                            ? "text-warning"
                            : isRadiologyDone === 0 && !xrayUploaded
                            ? "text-dark"
                            : "text-success"
                        }`}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Physical Pathology X-Ray"
                      ></i>
                    </Link>
                  </div>
                );
              },
            }),
          ]
        : []), // If roleid is 8, return an empty array
    ],
    [accessPerm, userDetails]
  );

  const table = useReactTable({
    data: userDetails,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  });

  const getUserDetails = async () => {
    try {
      setLoading(true);
      let response;
      if (roleId === 8) {
        response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/user-details/introducer/all`,
          { userId },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/user-details/all`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage?.getItem("jwtToken")}`,
            },
          }
        );
      }

      const data = response?.data?.data;
      if (data?.userDetails?.length !== 0) {
        calculateStatus(data?.userDetails);
        setTotalStatus(data?.userDetails?.length);
        if (data?.userDetails) {
          const sortData = data?.userDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserDetails(sortData ? sortData : data?.userDetails);
        }
        setCountryWiseUsers(data?.countryWiseUser);
        setPackageWiseUsers(data?.packageWiseUser);
        setIntroducerWiseUsers(data?.introducerWiseUser);
        setRepresentativeWiseUsers(data?.representativeWiseUser);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const reset = (e) => {
    const form = e.target.form;
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      const filterData = {
        fromDate: formattedDate,
        toDate: formattedDate,
        status: "",
        packageId: "",
        introducer: "",
        country: "",
        company: "",
        delegate: "",
        regNo: "",
      };

      methodFilterPatientsDetails(filterData, form);
      form.reset();
      setSelectedValues({
        package: null,
        introducer: null,
        country: null,
        company: null,
        delegate: null,
      });

      setRegInputField("");
    } catch (error) {
      console.error(error);
    }
  };

  const handlerSms = async (e) => {
    const sendSms = e.target.sms.value;

    e.preventDefault();
    const representativeCounts = new Map();

    const introducerUsersMap = {};
    const promises = [];

    //send notification
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/checked-notifications`, {
        checkedData: checkedUsers,
      })
      .then()
      .catch((error) => console.log(error));

    for (const checkedUser of checkedUsers) {
      const introducerMobile = checkedUser?.introducer_user?.mobile;

      if (introducerMobile) {
        if (!introducerUsersMap[introducerMobile]) {
          introducerUsersMap[introducerMobile] = [];
        }

        introducerUsersMap[introducerMobile].push(checkedUser);
      }
    }

    //first sms from first or second option to client or introducer and introducer
    for (const checkedUser of checkedUsers) {
      let recipientMobile;
      if (sendSms === "1") {
        if (checkedUser?.introducer_user?.SMSChecked === 0) {
          recipientMobile =
            checkedUser?.user?.mobile || checkedUser?.introducer_user?.mobile;
        } else {
          recipientMobile = checkedUser?.user?.mobile;
        }
      } else if (
        sendSms === "2" &&
        checkedUser?.introducer_user?.SMSChecked === 0
      ) {
        recipientMobile = checkedUser?.introducer_user?.mobile;
      }
      if (recipientMobile) {
        const verificationURL = `https://${process.env.REACT_APP_API_BASE_URL}:5005/medical-verification/result/report/print/${checkedUser?.user_id}`;
        const status = getStatusString(checkedUser?.user?.status);
        const clientName = `${checkedUser?.user?.f_name}`;
        const clientMessage = `Medical result of ${
          checkedUser?.user?.reg_prefix || ""
        }${checkedUser?.user?.registration_no} - ${clientName} for ${
          checkedUser?.country?.name
        } is ${status} Check here: ${verificationURL}.`;

        // Push the promise into the promises array
        promises.push(
          smsAPI(recipientMobile, clientMessage)
            .then((res) => {
              console.log("SMS sent to", recipientMobile, res);
            })
            .catch((err) => {
              console.log("Error sending SMS to", recipientMobile, err);
            })
        );
      }
    }
    //fix
    //second sms from second option to introducer
    for (const [introducerMobile, introducerCheckedUsers] of Object.entries(
      introducerUsersMap
    )) {
      const introducerFitCount = introducerCheckedUsers.filter(
        (user) => user?.user?.status === 2
      ).length;
      const introducerUnfitCount = introducerCheckedUsers.filter(
        (user) => user?.user?.status === 3
      ).length;
      const introducerHeldUpCount = introducerCheckedUsers.filter(
        (user) => user?.user?.status === 4
      ).length;

      const smsChecked = introducerCheckedUsers[0]?.introducer_user?.SMSChecked;
      // Create SMS message
      if (smsChecked === 0) {
        const statusCountsString = `Fit: ${introducerFitCount}, Unfit: ${introducerUnfitCount}, Held Up: ${introducerHeldUpCount}`;
        const introducerName = `${introducerCheckedUsers[0]?.introducer_user?.f_name}`;
        const message = `Introducer - ${introducerName} has patients who are ${statusCountsString}.`;

        // Send SMS to introducer
        const verificationURL = `https://${process.env.REACT_APP_API_BASE_URL}:5005/medical-verification/result/report/print/${introducerCheckedUsers[0]?.user_id}`;
        const introducerStatus = getStatusString(
          introducerCheckedUsers[0]?.user?.status
        );
        const introducerClientName = `${introducerCheckedUsers[0]?.user?.f_name}`;
        const introducerClientMessage = `Medical result of  ${
          introducerCheckedUsers[0]?.user?.reg_prefix || ""
        }${
          introducerCheckedUsers[0]?.user?.registration_no
        } - ${introducerClientName} for ${
          introducerCheckedUsers[0]?.country?.name
        } is ${introducerStatus}. Please click the link ${verificationURL} to show your medical result.  Thank You! ${verificationURL}. ${message}`;

        promises.push(
          smsAPI(introducerMobile, introducerClientMessage)
            .then((res) => {
              console.log("SMS sent to", introducerMobile, res);
            })
            .catch((err) => {
              console.log("Error sending SMS to", introducerMobile, err);
            })
        );
      }
    }

    //third sms from second option to representative
    // for (const checkedUser of checkedUsers) {
    //   const representativeMobile =
    //     checkedUser?.introducer_user?.user_users_maps?.map(
    //       (user) => user?.representative_user_map?.mobile
    //     )[0];

    //   if (representativeMobile) {
    //     const representativeStatusCounts = representativeCounts.get(
    //       representativeMobile
    //     ) || {
    //       fit: 0,
    //       unfit: 0,
    //       heldUp: 0,
    //     };

    //     const userStatus = checkedUser?.user?.status;
    //     if (userStatus === 2) {
    //       representativeStatusCounts.fit++;
    //     } else if (userStatus === 3) {
    //       representativeStatusCounts.unfit++;
    //     } else if (userStatus === 4) {
    //       representativeStatusCounts.heldUp++;
    //     }

    //     representativeCounts.set(
    //       representativeMobile,
    //       representativeStatusCounts
    //     );
    //   }
    // }

    // Iterate through the representativeCounts and send SMS
    // for (const [representativeMobile, statusCounts] of representativeCounts) {
    //   const verificationURL = `https://${process.env.REACT_APP_API_BASE_URL}:5005/medical-verification/result/report/print/${representativeMobile}`;
    //   const statusCountsString = `Fit: ${statusCounts.fit}, Unfit: ${statusCounts.unfit}, Held Up: ${statusCounts.heldUp}`;
    //   const representativeMessage = `Your patients have status counts - ${statusCountsString}. Check here: ${verificationURL}.`;

    //   // Push the promise into the promises array
    //   promises.push(
    //     smsAPI(representativeMobile, representativeMessage)
    //       .then((res) => {
    //         console.log(
    //           "SMS sent to representative",
    //           representativeMobile,
    //           res
    //         );
    //       })
    //       .catch((err) => {
    //         console.log(
    //           "Error sending SMS to representative",
    //           representativeMobile,
    //           err
    //         );
    //       })
    //   );
    // }

    // Wait for all the promises to resolve
    try {
      await Promise.all(promises);
      toast.success("SMS sent successfully!");
    } catch (error) {
      toast.error("Failed to send some SMS messages");
    }
  };

  const getStatusString = (status) => {
    switch (status) {
      case 2:
        return "FIT";
      case 3:
        return "UNFIT";
      case 4:
        return "HELD-UP";
      default:
        return "PROCESSING";
    }
  };

  useEffect(() => {
    const selectedRows = table
      .getSelectedRowModel()
      ?.flatRows?.map((row) => row.original);
    setCheckedUsers(selectedRows);
  }, [table.getSelectedRowModel()]);

  useEffect(() => {
    getUserDetails();
  }, []);
  console.log(roleId, userId);
  // useEffect(() => {
  //   handlerLocationSearch();
  // }, [location?.state]);
  return (
    <>
      <div className="card medical-report-container">
        <div className="card-body">
          <div className="rounded">
            <div className="card shadow-none mb-3">
              <div className="border p-3 rounded">
                <h6
                  className="mb-0 btn btn-danger text-white text-capitalize fw-bold m-0 p-0"
                  style={{ cursor: "default" }}
                >
                  Medical Reports
                </h6>
                <div className="d-flex flex-wrap">
                  <form
                    onSubmit={(e) => handlerOnSearch(e)}
                    className="col-sm-12"
                  >
                    <div className="d-flex flex-wrap">
                      <div className="p-1">
                        <label className="col-form-label">
                          From <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            name="from_date"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">
                          To <span className="text-danger">*</span>
                        </label>
                        <div className="">
                          <input
                            type="date"
                            data-date-format="YYYY MM DD"
                            defaultValue={format(new Date(), "yyyy-MM-dd")}
                            name="to_date"
                            className="form-control medical-form-select py-2 fw-bold"
                            placeholder="Date Picker..."
                            required={regInputField ? false : true}
                            style={{ maxWidth: "200px" }}
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Status</label>
                        <div className="fw-light">
                          <select
                            className="form-select medical-form-select py-2 fw-bold"
                            name="status"
                            aria-label="Default select example"
                            style={{ maxWidth: "140px" }}
                          >
                            <option value={""}>Select Status</option>
                            <option value={1}>PROCESSING</option>
                            <option value={2}>FIT</option>
                            <option value={3}>UNFIT</option>
                            <option value={4}>HELD-UP</option>
                          </select>
                        </div>
                      </div>
                      <div className="p-1">
                        <label className="col-form-label">Package</label>
                        <div className="fw-light">
                          <Select
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minWidth: 140,
                                color: "black",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "black",
                              }),
                            }}
                            components={{
                              MenuList: VirtualizedMenuListPackage,
                            }}
                            name="package"
                            options={packageOptions}
                            value={selectedValues.package}
                            isSearchable={true}
                            onChange={(selectedOptions) =>
                              handleSelect("package", selectedOptions)
                            }
                          />
                        </div>
                      </div>
                      {roleId !== 8 && (
                        <div className="p-1">
                          <label className="col-form-label">Introducer</label>
                          <div className="fw-light">
                            <Select
                              components={{ MenuList: VirtualizedMenuList }}
                              options={options}
                              placeholder="Select Introducer"
                              onChange={(selectedOptions) =>
                                handleSelect("introducer", selectedOptions)
                              }
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  color: "black",
                                  width: 300,
                                  maxWidth: 320,
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "black",
                                }),
                              }}
                              isSearchable={true}
                              value={selectedValues.introducer}
                              name="introducer"
                            />
                          </div>
                        </div>
                      )}
                      <div className=" p-1">
                        <label className="col-form-label tdt">
                          Registration/Passport No.
                        </label>
                        <div className="">
                          <input
                            style={{ width: "180px", height: "37px" }}
                            type="text"
                            onChange={(e) => setRegInputField(e.target.value)}
                            name="invoice_reg_mobile"
                            className="form-control medical-form-select py-2"
                          />
                        </div>
                      </div>
                      <div className="p-1 d-flex align-items-end justify-content-end">
                        <div className="col-form-label">
                          <button
                            type="submit"
                            className="btn text-white bg-primary border border-3"
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning border border-3"
                            onClick={(e) => reset(e)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  {accessPerm(33, 25) && roleId !== 8 && (
                    <div className="d-flex flex-wrap align-items-end gap-0 justify-content-center mt-2 w-100">
                      <form
                        className="d-flex align-items-center gap-2"
                        onSubmit={(e) => handlerSms(e)}
                        style={{ width: "450px" }}
                      >
                        <select
                          name="sms"
                          id="sms"
                          className="w-100 form-select"
                          defaultValue=""
                        >
                          <option value="">Select an Action</option>
                          <option value="1">Send SMS To Client</option>
                          <option value="2">Send SMS To Introducer</option>
                        </select>
                        <button type="submit" className="btn btn-warning">
                          Apply
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="patient-info-table-search">
              <div className="fit-unfit-status">
                <span className="fw-bolder">Record Summary &gt; </span>
                <span className="text-success fw-bolder">{`FIT: ${statusCounts?.status2}`}</span>
                <span> | </span>
                <span className="text-danger fw-bolder">{`UNFIT: ${statusCounts?.status3}`}</span>
                <span> | </span>
                <span className="fw-bolder" style={{ color: "#0000FF" }}>
                  {`HELD-UP: ${statusCounts?.status4}`}
                </span>
                <span> | </span>
                <span className="fw-bolder">{`Others: ${otherStatus}`}</span>
                <span> | </span>
                <span className="fw-bolder">{`Total: ${totalStatus}`}</span>
              </div>
              <div className="reg-summary d-flex justify-content-end">
                <span className="me-1">Summary Of:</span>{" "}
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#country-summary-modal"
                >
                  Country
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#agency-summary-modal"
                >
                  Agency
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#repres-summary-modal"
                >
                  Representative
                </span>{" "}
                <span className="mx-1">|</span>
                <span
                  className="text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#package-summary-modal"
                >
                  Package
                </span>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <table className="table align-middle table-hover table-bordered mb-0 rounded">
                <thead className="bg-primary">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, i) => (
                        <th
                          key={i}
                          id={`th-${i}`}
                          className="text-light p-1"
                          style={{ backgroundColor: "#095d7e" }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, i) => {
                    const {
                      original: {
                        user: {
                          status,
                          is_radiology_done,
                          is_pathology_done,
                          is_physical_done,
                        },
                      },
                    } = row;
                    const collection =
                      row?.original?.user?.user_collections?.map(
                        (collection) =>
                          collection.investigation_category
                            ?.investigation_group_id
                      );

                    const uniqueCollection = [...new Set(collection)];
                    const done =
                      (uniqueCollection?.length < 2 &&
                        (is_pathology_done ||
                          is_physical_done ||
                          is_radiology_done)) ||
                      (uniqueCollection?.length >= 2 &&
                        uniqueCollection?.length < 3 &&
                        (is_pathology_done ? 1 : 0) +
                          (is_physical_done ? 1 : 0) +
                          (is_radiology_done ? 1 : 0) >=
                          2) ||
                      (uniqueCollection?.length >= 3 &&
                        is_pathology_done &&
                        is_physical_done &&
                        is_radiology_done);

                    return (
                      <tr
                        key={i}
                        className={`${done && status === 4 && "text-primary"} ${
                          done && status === 2 && "text-success"
                        } ${done && status === 3 && "text-danger"} ${
                          status === 1 && "text-dark"
                        } 
         `}
                      >
                        {row.getVisibleCells().map((cell, i) => (
                          <td key={i} className="p-1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <div>
        <CountrySummaryModal countryWiseUsers={countryWiseUsers} />
        <AgencySummaryModal introducerWiseUsers={introducerWiseUsers} />
        <PackageSummaryModal packageWiseUsers={packageWiseUsers} />
        <RepresentativeSummeryModal
          representativeWiseUsers={representativeWiseUsers}
        />
      </div>
    </>
  );
};

export default React.memo(MedicalReportsCopy);
