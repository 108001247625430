import { useState } from "react";

export function useMultistepForm(steps) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    function next() {
        setCurrentStepIndex((i) => (i < steps.length - 1 ? i + 1 : i));
    }

    function back() {
        setCurrentStepIndex((i) => (i > 0 ? i - 1 : i));
    }

    return {
        steps,
        currentStepIndex,
        step: steps[currentStepIndex].component,
        title: steps[currentStepIndex].title,
        isFirstStep: currentStepIndex === 0,
        isLastStep: currentStepIndex === steps.length - 1,
        next,
        back,
    };
}
