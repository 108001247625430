import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./IndexPage.scss";
import NavbarModule from "../../../components/Navbar/NavbarModule";
import Banner from "../../../components/Banner/Banner";
import About from "../../../components/About/About";
import ChooseUs from "../../../components/ChooseUs/ChooseUs";
import Tours from "../../../components/Tours/Tours";
import Category from "../../../components/Category/Category";
import Video from "../../../components/Video/Video";
import Place from "../../../components/Place/Place";
import Testimonials from "../../../components/Testimonials/Testimonials";
import News from "../../../components/News/News";
import Subscribe from "../../../components/Subscribe/Subscribe";
import Footer from "../../../components/Footer/Footer";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import SearchByDestinations from "../../../components/SearchFilter/SearchByDestinations/SearchByDestinations";
import AboutIndex from "../../../components/About/AboutIndex";
import Destination from "../../../components/Destination/Destination";
import Tour from "../../../components/Tour/Tour";
import TourIndex from "../../../components/TourIndex/TourIndex";
import TourTypeIndex from "../../../components/TourTypeIndex/TourTypeIndex";
import PackageTab from "../../../components/PackageTab/PackageTab";
import Testimonial from "../../../components/Testimonial/Testimonial";
import Offers from "../../../components/Offers/Offers";
import BlogIndex from "../../../components/BlogIndex/BlogIndex";
import VisaIndex from "../../../components/VisaIndex/VisaIndex";
import WhyChooseUs from "../../../components/WhyChooseUs/WhyChooseUs";
import Loading from "../../../utils/Loader/Loading";
import useFetchData from "../../../hooks/useFetch/useFetchData";
import Error from "../../../utils/Error/Error";
import CorporateLogo from "../../../components/CorporateLogo/CorporateLogo";
import MultistepForm from "../../../components/MultistepForm/MultistepForm";
import { Swal } from 'sweetalert2';

const IndexPage = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Show the modal when the component mounts
    setIsModalOpen(true);
  }, []);

  const { data: settingsRes, isLoading: isLoadingSettings, isError: isErrorSettings } = useFetchData(
    'settings',
    `settings`
  );
  const { data: welcome_messages, isLoading: isLoadingWelcome_messages, isError: isErrorBrand_welcome_messages } = useFetchData(
    'welcome_messages',
    `welcome_messages/active`
  );
  const { data: brandRes, isLoading: isLoadingBrandRes, isError: isErrorBrand } = useFetchData(
    'footer',
    `footer`
  );

  const { data: menuRes, isLoading: isLoadingMenu, isError: isErrorMenu } = useFetchData(
    'menu',
    `menu-group/active/menu-item/website`
  );

  const { data: slidesRes, isLoading: isLoadingSlide, isError: isErrorSlide } = useFetchData("banner", "banner/active");

  const { data: destinationData, isLoading: isLoadingDestination, isError: isErrorDestination } = useFetchData('destination', 'destination/active');

  const { data: tourPackagesData, isLoading: isLoadingTours, isError: isErrorTours } = useFetchData('package', 'package-management/packages');

  const { data: offerDataRes, isLoading: isLoadingOffers, isError: isErrorOffers } = useFetchData('offer', 'offer/active');

  const { data: tourTypeRes, isLoadingTourType, isErrorTourType } = useFetchData('tourType', 'tourType/active');

  const tourTypes = tourTypeRes?.data || [];


  if (isLoadingSettings ||
    isLoadingWelcome_messages ||
    isLoadingBrandRes ||
    isLoadingMenu ||
    isLoadingTours ||
    isLoadingDestination ||
    isLoadingSlide ||
    isLoadingOffers ||
    isLoadingTourType) return <div className=""><Loading /></div>;

  // if (isErrorSettings) return <div> <Error isError={isErrorSettings} /> </div>;
  // if (isErrorBrand_welcome_messages) return <div> <Error isError={isErrorBrand_welcome_messages} /> </div>;
  if (isErrorBrand) return <div> <Error isError={isErrorBrand} /> </div>;
  if (isErrorMenu) return <div> <Error isError={isErrorMenu} /> </div>;
  if (isErrorSlide) return <div> <Error isError={isErrorSlide} /> </div>;
  if (isErrorTours) return <div>
    {/* <Error isError={isErrorTours} /> */}
    console.log("package Error", isErrorTours)
  </div>;
  if (isErrorOffers) return <div>
    {/* <Error isError={isErrorTours} /> */}
    console.log("package Error", isErrorTours)
  </div>;
  if (isErrorDestination) return <div> <Error isError={isErrorDestination} /> </div>;
  if (isErrorTourType) return <div> <Error isError={isErrorTourType} /> </div>;

  return (
    <div >
      {/* <ScrollToTop smooth /> */}
      <div className='relative'>



        {/* Login Modal */}

        {/* Start header section */}
        {/* <NavbarModule
          welcome_messages={welcome_messages}
          brandRes={brandRes}
        //   menuRes={menuRes}
        //   tourPackagesData={tourPackagesData}
        //   settingsRes={settingsRes}
        // /> */}
        {/* Start Welcome Modal */}
        {/* {isModalOpen && (
          <MultistepForm />)
        } */}
        {/* End Welcome Modal */}
        {/* test popup */}
        {/* {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 modalContainer">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm modalForm">
              <h2 className="text-xl font-semibold">Welcome to Our Website!</h2>
              <p className="mt-2 text-gray-600">Enjoy exploring our content.</p>

              <button
                className="btn btn-error mt-4 w-full"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        )} */}
        {/* End header section */}
        <Banner slidesRes={slidesRes} />
        {/* Start Home1 About section */}
        <AboutIndex />
        {/* End Home1 About section */}
        {/* Start Destination masonary section */}
        <Destination />
        {/* End Destination masonary section */}
        {/* Start Tour Facilites  section */}
        <Tour />
        {/* End Tour Facilites  section */}
        {/* Start Facility section */}

        {/* End Facility section */}
        {/* Start TourType section */}
        <TourTypeIndex />
        {/* End TourType section */}
        {/* Start Packages section */}
        <TourIndex />

        {/* End Packages section */}
        {/* Start Why Choose Us Card section */}
        <div className="paddingbottom">
          <WhyChooseUs />
        </div>
        {/* End Feature Card section */}
        {/* Start package Card tab section */}
        <PackageTab
          tourPackagesData={tourPackagesData}
          destinationData={destinationData}
          settingsRes={settingsRes}
        />
        {/* End package Card tab section */}
        {/* Start Activities tab section */}

        {/* End Activities tab section */}
        {/* Start Offers section */}
        <div className="">
          <Offers offerDataRes={offerDataRes} />
        </div>
        {/* End Offers section */}
        {/* Start Visa section section */}
        <VisaIndex />
        {/* End Visa section section */}
        {/* Start Testimonial section */}
        <Testimonial />
        {/* End Testimonial section */}
        <div className="paddingtop">
          <CorporateLogo />
        </div>
        {/* Start blog section */}
        <BlogIndex />
        {/* End blog section */}

        {/* Start Banner3 section section */}
        <Subscribe />
        {/* End Banner3 section section */}
        {/* Start Footer Section */}
        {/* <Footer /> */}

        {/* End Footer Section */}
      </div>

    </div>

  )
};

export default IndexPage;
