import { FormWrapper } from "./FormWrapper";

export function PopulationCountForm({
    adults,
    children,
    updateFields,
}) {
    return (
        <FormWrapper title="Travellers">
            <div className="form-group mb-3">
                <label htmlFor="adultsInput">Number of Adults</label>
                <input
                    type="number"
                    id="adultsInput"
                    className="form-control"
                    min="1"
                    value={adults || ""}
                    onChange={e => updateFields({ adults: e.target.value })}
                    placeholder="Enter number of adults"
                />
            </div>

            <div className="form-group">
                <label htmlFor="childrenInput">Number of Children</label>
                <input
                    type="number"
                    id="childrenInput"
                    className="form-control"
                    min="0"
                    value={children || ""}
                    onChange={e => updateFields({ children: e.target.value })}
                    placeholder="Enter number of children"
                />
            </div>
        </FormWrapper>
    );
}
