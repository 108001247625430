import React from 'react';
import Subscribe from '../../components/Subscribe/Subscribe';
import useFetchData from '../../hooks/useFetch/useFetchData';
import Loading from '../../utils/Loader/Loading';
import Error from '../../utils/Error/Error';
import { Link } from 'react-router-dom';
import { img_url } from '../../utils/mainurl/mainurl';
import LoadingSm from '../../utils/Loader/LoadingSm';
import VisaCard from '../../components/VisaCard/VisaCard';

const VisasPageFiltered = ({ packages, handleClearFilter }) => {

    // const { data: tourPackagesData, isLoading: isLoadingTours, isError: isErrorTours } = useFetchData('package', 'package-management/packages');

    // const packages = tourPackagesData?.data || [];

    console.log("VisasPageFiltered Visas ", packages)

    // if (isLoadingTours) return <div className=""><Loading /></div>;

    // if (isErrorTours) return <div> <Error isError={isErrorTours} /> </div>;

    return (
        <div>
            <>
                {
                    packages?.length > 0 ?
                        <>
                            <div className='d-flex flex-column align-items-center justify-content-center paddingtop'>
                                <h1>
                                    Filted Results for Visa
                                </h1>
                                <div className='row d-flex justify-content-center w-25 p-3'>
                                    {/* <div> */}
                                    <button
                                        onClick={handleClearFilter}
                                        className='btn btn-danger w-50'>
                                        Clear Filter
                                    </button>
                                    {/* </div> */}
                                </div>
                            </div>
                            {/* Start Package Grid section */}
                            <div className="package-grid-section pt-1"
                            >

                                <div className="container  shadow-sm p-3 mb-5 bg-body rounded"
                                    style={{
                                        // backgroundColor: '#edf2de'
                                    }}>

                                    {/* <div className="row gy-5 ">
                                    <div className='d-flex justify-content-center eg-tag'>
                                        <h1>Filterd Items</h1>
                                    </div>
                                </div> */}
                                    {/* <div className='row d-flex justify-content-center w-25 p-3'>
                                        <div>
                                            <button
                                                onClick={handleClearFilter}
                                                className='btn btn-danger'>
                                                Clear Filter
                                            </button>
                                        </div>
                                    </div> */}
                                    <div className="row gy-5 mb-70">
                                        {
                                            // packages && 
                                            packages?.map((pkg, index) => <div key={index} className="col-lg-4 col-md-6">
                                                <VisaCard visaOne={pkg} />
                                            </div>)
                                        }

                                    </div>

                                </div>
                            </div>
                        </> : <div>No Data Found</div>
                }

            </>

        </div>
    );
};

const VisaIndexCard = ({ visaOne }) => {

    console.log("visaOne ", visaOne)

    const { data: settingsRes, isLoading: isLoadingSettings, isError: isErrorSettings } = useFetchData(
        'settings',
        `settings`
    );
    const { currency, vat_type } = settingsRes?.data[0]

    if (isLoadingSettings) return <div className=""><LoadingSm /></div>;

    return (
        <div className="swiper-slide"
            style={{
                maxWidth: '363px',
                maxHeight: '363px', // lg Screen
                minWidth: '100%',
                minHeight: '100%', // lg Screen
                // width: '372px', height: '258px', // mobile
                objectFit: 'cover'
                // Ensures the image covers the entire area without distortion 
            }}
        >
            <div className="package-card2">
                <Link to={`visa/${visaOne?.id}`}>
                    <img
                        src={`${img_url}${visaOne?.image}`}
                        alt=""
                        style={{
                            // minWidth: '363px',
                            // minHeight: '363px', // lg Screen
                            // maxWidth: '100%',
                            // maxHeight: '100%', // lg Screen
                            maxWidth: '363px',
                            maxHeight: '363px', // lg Screen
                            minWidth: '100%',
                            minHeight: '100%', // lg Screen
                            // width: '372px', height: '258px', // mobile
                            objectFit: 'cover'
                            // Ensures the image covers the entire area without distortion 
                        }}
                    />
                </Link>
                <div className="eg-tag">
                    <h4>
                        <Link to={`visa/${visaOne?.id}`}><div>Visa</div></Link>
                    </h4>
                </div>
                <div className="package-card2-content">
                    <div className="title">
                        <h6>{visaOne?.name}</h6>
                    </div>
                    <div className="price-area">
                        <span>{currency}{visaOne?.price}</span>
                        {
                            vat_type == 1 ? <p>TAXES INCL/PERS</p> : <p>NO TAXES</p>
                        }
                        {/* <p>TAXES INCL/PERS</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisasPageFiltered;