import axios from "axios";
import React, { useState } from "react";
import { mainurl } from "../../utils/mainurl/mainurl";

const Subscribe = () => {

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: ''
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (process.env.NODE_ENV === "development") console.log("Sub", formData);
    try {
      const response = await axios.post(`${mainurl}newsletterSubscription`, formData);
      if (process.env.NODE_ENV === "development") console.log('Response:', response.data);
    }
    catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="banner3-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="banner3-content">
              <h2>Join The Newsletter</h2>
              <p>To receive our best monthly deals</p>
              <form onSubmit={handleSubmit}>
                <div className="from-inner">
                  <input type="text" placeholder="Enter Your Name..." name="name" value={formData.name} onChange={handleChange} />
                  <input type="text" placeholder="Enter Your Phone..." name="phone" value={formData.phone} onChange={handleChange} />
                  <input type="email" placeholder="Enter Your Email..." name="email" value={formData.email} onChange={handleChange} />
                  <button type="submit" className="from-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={17} viewBox="0 0 18 17" >
                      <path d="M7 1L16 8.5M16 8.5L7 16M16 8.5H0.5" strokeWidth="1.5" />
                    </svg>
                  </button>
                </div>
              </form>
              <img src="../../assets/img/home1/banner3-vector1.png" alt="" className="vector1" />
              <img src="../../assets/img/home1/banner3-vector2.png" alt="" className="vector2" />
            </div>
          </div>
        </div>
      </div>
    </div>);

  return (

    <>

      <div className="banner3-section">

        <div className="container">

          <div className="row">
            <div className="col-lg-12">
              <div className="banner3-content">
                <h2>Join The Newsletter</h2>
                <p>To receive our best monthly deals</p>
                <form>
                  <div className="from-inner">
                    <input
                      type="email" name="email" placeholder="Enter Your Email..." value={formData.email} onChange={handleChange}
                    />
                    <button type="submit" className="from-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={17}
                        viewBox="0 0 18 17"
                      >
                        <path
                          d="M7 1L16 8.5M16 8.5L7 16M16 8.5H0.5"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
                <img
                  src="assets/img/home1/banner3-vector1.png"
                  alt=""
                  className="vector1"
                />
                <img
                  src="assets/img/home1/banner3-vector2.png"
                  alt=""
                  className="vector2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <section className="subscribe-section">
      <div className="bg-color" />
      <div className="auto-container">
        <div className="inner-container">
          <div
            className="shape"
            style={{
              backgroundImage: "url(assets/images/shape/shape-4.png)",
            }}
          />
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-column">
              <div className="text-box mr_50">
                <h2>Subscribe for latest update about Travelling</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 form-column">
              <div className="form-inner ml_65">
                <form
                  method="post"
                  action="https://azim.hostlin.com/Travic/contact.html"
                >
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address*"
                      required=""
                    />
                    <button type="submit">
                      Subscribe
                      <i className="fas fa-long-arrow-right" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
