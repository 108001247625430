import { FormWrapper } from "./FormWrapper"

export function WhatsappComunication({
  whatsappComunication,
  updateFields,
}) {
  return (
    <FormWrapper title="Do You Prefer Comunication via Whatsapp">
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="whatsappComunication"
          id="whatsappComunication-yes"
          value="Yes"
          checked={whatsappComunication === "Yes"} // ✅ Maintain selection
          onChange={e => updateFields({ whatsappComunication: e.target.value })}
        />
        <label className="form-check-label" htmlFor="whatsappComunication-yes">
          Yes
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="whatsappComunication"
          id="whatsappComunication-no"
          value="No"
          checked={whatsappComunication === "No"} // ✅ Maintain selection
          onChange={e => updateFields({ whatsappComunication: e.target.value })}
        />
        <label className="form-check-label" htmlFor="whatsappComunication-no">
          No
        </label>
      </div>
    </FormWrapper>
  );
}

