import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/css/bootstrap.min.css'
import { RouterProvider } from "react-router-dom";
import router from "./Routers/Router";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import './assets/css/all.min.css'
import './assets/css/animate.min.css'
import './assets/css/style.css'
import './assets/css/slick-theme.css'
import './assets/css/slick.css'
import './assets/css/select2.css'
import './assets/css/nice-select.css'
// import './assets/css/jquery-ui.css'
// import './assets/css/jquery.fancybox.min.css'
import './assets/css/fontawesome.min.css'
import './assets/css/daterangepicker.css'
import './assets/css/dashboard.css'
import './assets/css/boxicons.min.css'
import './assets/css/bootstrap.min.css'
import "./index.css";

// jquery
// import './assets/js/jquery-ui.js';
// import './assets/js/moment.min.js';
// import './assets/js/daterangepicker.min.js';
// import './assets/js/bootstrap.min.js';
// import './assets/js/popper.min.js';
// import './assets/js/swiper-bundle.min.js';
// import './assets/js/slick.js';
// import './assets/js/waypoints.min.js';
// import './assets/js/jquery.counterup.min.js';
// import './assets/js/isotope.pkgd.min.js';
// import './assets/js/jquery.magnific-popup.min.js';
// import './assets/js/jquery.marquee.min.js';
// import './assets/js/jquery.nice-select.min.js';
// import './assets/js/select2.min.js';
// import './assets/js/jquery.fancybox.min.js';
// import './assets/js/custom.js';

function App() {

  // Create a client
  const queryClient = new QueryClient()
  // <QueryClientProvider client={queryClient}>
  // </QueryClientProvider>

  return (
    <>
      <div className="App">{
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      }</div>
    </>
  );
}

export default App;
