import React, { useState } from 'react';
import { useMultistepForm } from './useMultistepForm';
import { UserForm } from './UserForm';
import { AddressForm } from './AddressForm';
import { AccountForm } from './AccountForm';
import './MultistepForm.css'
import Swal from 'sweetalert2';
import axios from 'axios';
import { mainurl } from '../../utils/mainurl/mainurl';

// const INITIAL_DATA = {
//     firstName: "",
//     lastName: "",
//     age: "",
//     street: "",
//     city: "",
//     state: "",
//     zip: "",
//     email: "",
//     password: "",
// }

const MultistepForm = ({ data, steps, currentStepIndex, step, isFirstStep, isLastStep, back, next, handleClose }) => {
    // const [data, setData] = useState(INITIAL_DATA)
    console.log("MultiSForm Data", data);
    // function updateFields(fields) {
    //     setData(prev => {
    //         return { ...prev, ...fields }
    //     })
    // }
    // const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    //     useMultistepForm([
    //         <UserForm {...data} updateFields={updateFields} />,
    //         <AddressForm {...data} updateFields={updateFields} />,
    //         <AccountForm {...data} updateFields={updateFields} />,
    //     ])

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isLastStep) return next()
        // alert("Successful Account Creation")
        // send data to backend
        try {
            const response = await axios.post(`${mainurl}popupform`, data);
            console.log("Response:", response.data);
            if (response.data) {
                Swal.fire({
                    title: "Thank You!",
                    icon: "success",
                    draggable: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button'  // Add custom class for the button
                    }
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    customClass: {
                        confirmButton: 'custom-confirm-button'  // Add custom class for the button
                    }
                });
            }
        } catch (error) {
            console.error("There was an error!", error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                customClass: {
                    confirmButton: 'custom-confirm-button'  // Add custom class for the button
                }
            });
        }

        handleClose()
    }

    const style = document.createElement('style');
    style.textContent = `
      .custom-confirm-button {
        background-color: #63ab45 !important;
      }
    `;
    document.head.append(style);

    return (
        <div
            style={{
                position: "relative",
                background: "white",
                // border: "1px solid black",
                padding: "0 2rem",
                // padding: "2rem",
                // margin: "1rem",
                borderRadius: ".5rem",
                fontFamily: "Arial",
                maxWidth: "max-content",
            }}
            className='multi-form-container'
        >
            <form onSubmit={onSubmit}>
                {/* <div style={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
                    {currentStepIndex + 1} / {steps.length}
                </div> */}
                {step}
                <div
                    style={{
                        // marginTop: "1rem",
                        display: "flex",
                        gap: ".5rem",
                        justifyContent: "flex-end",
                    }}
                >
                    {!isFirstStep && (
                        <button type="button" className="primary-btn2 btn btn-danger" onClick={back}>
                            Back
                        </button>
                    )}
                    <button className="primary-btn2" type="submit">{isLastStep ? "Finish" : "Next"}</button>
                </div>
            </form>
        </div>
    )
};

export default MultistepForm;