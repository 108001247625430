// api.js
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { mainurl } from "../../utils/mainurl/mainurl";
// import { fetchData } from "./api";

// const baseURL = `${process.env.REACT_APP_API_BASE_URL}/`;
const baseURL = mainurl;

export const fetchData = async (url) => {
    const { data } = await axios.get(baseURL + url);
    return data;
};

const useFetchData = (key, url) => {
    return useQuery({
        queryKey: [key], // Unique key for caching
        queryFn: async () => await fetchData(url), // Fetch function
        staleTime: 5 * 60 * 1000, // Data is considered fresh for 5 minutes
        cacheTime: 10 * 60 * 1000, // Cache duration
        refetchOnWindowFocus: false, // Prevent refetch on window focus
    });
};

export default useFetchData;
