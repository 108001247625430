import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
// import { BrowserRouter } from 'react-router-dom';
import App from "./App";
import UserContext from "./Context/UserContextAPI";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 3500,
        style: {
          background: "#363636",
          color: "#fff",
          minWidth: "200px", // Increase the minimum width
        },
      }}
    />
    <UserContext>
      <App />
    </UserContext>
  </>
);
