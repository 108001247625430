import React from 'react';
import Subscribe from '../../components/Subscribe/Subscribe';
import useFetchData from '../../hooks/useFetch/useFetchData';
import Loading from '../../utils/Loader/Loading';
import Error from '../../utils/Error/Error';
import PackageCard from '../../components/PackageCard/PackageCard';
import { Link } from 'react-router-dom';

const ToursPageFiltered = ({ packages, handleClearFilter }) => {

    // const { data: tourPackagesData, isLoading: isLoadingTours, isError: isErrorTours } = useFetchData('package', 'package-management/packages');

    // const packages = tourPackagesData?.data || [];

    console.log("packages", packages)

    // if (isLoadingTours) return <div className=""><Loading /></div>;

    // if (isErrorTours) return <div> <Error isError={isErrorTours} /> </div>;

    return (
        <div>
            <>
                {packages?.length > 0 &&
                    <>
                        <div className='d-flex flex-column align-items-center justify-content-center paddingtop'>
                            <h1>
                                Filted Results for Tour Packages
                            </h1>
                            <div className='row d-flex justify-content-center w-25 p-3'>
                                {/* <div> */}
                                    <button
                                        onClick={handleClearFilter}
                                        className='btn btn-danger w-50'>
                                        Clear Filter
                                    </button>
                                {/* </div> */}
                            </div>
                        </div>
                        {/* Start Package Grid section */}
                        <div className="package-grid-section pt-1"
                        >

                            <div className="container  shadow-sm p-3 mb-5 bg-body rounded"
                                style={{
                                    // backgroundColor: '#edf2de'
                                }}>

                                {/* <div className="row gy-5 ">
                                    <div className='d-flex justify-content-center eg-tag'>
                                        <h1>Filterd Items</h1>
                                    </div>
                                </div> */}

                                <div className="row gy-5 mb-70">
                                    {
                                        packages && packages?.map((pkg, index) => <div key={index} className="col-lg-4 col-md-6">
                                            <PackageCard pkg={pkg} />
                                        </div>)
                                    }

                                </div>

                            </div>
                        </div>
                    </>
                }

            </>

        </div>
    );
};

export default ToursPageFiltered;