import { Form } from "react-bootstrap";
import useFetchData from "../../hooks/useFetch/useFetchData";
import LoadingSm from "../../utils/Loader/LoadingSm";
import { FormWrapper } from "./FormWrapper"

export function TourTypeForm({
  tourtype,
  updateFields,
  // firstName,
  // lastName,
  // age,
}) {
  const { data: tourTypeRes, isLoadingTourType, isErrorTourType } = useFetchData('tourType', 'tourType/active');

  const tourTypes = tourTypeRes?.data;
  console.log("tourTypes", tourTypes)

  if (isLoadingTourType) return (<LoadingSm />)
  return (
    <FormWrapper title="Tour Types">
      {
        tourTypes?.map((onee) => <>
          <div class="form-check">
            <input class="form-check-input" type="radio"
              // name={`flexRadioDefault-${onee.id}`} 
              // name="flexRadioDefault1" 
              name="tourtype" // All radios should share the same name
              id={`flexRadioDefault-${onee.id}`}
              checked={tourtype === onee.name}  // ✅ Maintain selection
              value={onee.name}
              onChange={e => updateFields({ tourtype: e.target.value })}
            />
            <label class="form-check-label"
              // for="flexRadioDefault1"
              htmlFor={`flexRadioDefault-${onee.id}`}
            >
              {onee?.name}
            </label>
          </div>
        </>)
      }
    </FormWrapper>
  )
}
