import React from 'react';
import { ThreeDot } from 'react-loading-indicators';

const LoadingSm = () => {
    return (
        <>
            <div className="d-flex justify-content-center my-5" >
                <ThreeDot color="#63ab45" size="small" text="" textColor="" />
                {/* <Skeleton height={30} /> */}
                {/* <Skeleton count={5} />  */}
                {/* <Loading /> */}
            </div>
        </>
    );
};

export default LoadingSm;