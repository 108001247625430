import { Form } from "react-bootstrap";
import useFetchData from "../../hooks/useFetch/useFetchData";
import LoadingSm from "../../utils/Loader/LoadingSm";
import { FormWrapper } from "./FormWrapper"

export function DestinationForm({
  destination,
  updateFields,
  // firstName,
  // lastName,
  // age,
}) {
  const { data: destinationData, isLoading: isLoadingDestination, isError: isErrorDestination } = useFetchData('destination', 'destination/active');

  const destinations = destinationData?.data;
  console.log("Destinations", destinations)

  if (isLoadingDestination) return (<LoadingSm />)
  return (
    <FormWrapper title="Destinations">
      {
        destinations?.map((onee) => <>
          <div class="form-check">
            <input class="form-check-input" type="radio"
              // name={`flexRadioDefault-${onee.id}`} 
              // name="flexRadioDefault1" 
              name="destination" // All radios should share the same name
              id={`flexRadioDefault-${onee.id}`}
              checked={destination === onee.country_name}  // ✅ Maintain selection
              value={onee.country_name}
              onChange={e => updateFields({ destination: e.target.value })}
            />
            <label class="form-check-label"
              // for="flexRadioDefault1"
              htmlFor={`flexRadioDefault-${onee.id}`}
            >
              {onee?.country_name}
            </label>
          </div>
        </>)
      }
    </FormWrapper>
  )
}
