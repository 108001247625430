import { FormWrapper } from "./FormWrapper";

export function DurationForm({
    duration,
    updateFields,
}) {
    return (
        <FormWrapper title="Select Duration (Days)">
            <div className="form-group">
                <label htmlFor="durationInput">Enter Number of Days</label>
                <input
                    type="number"
                    id="durationInput"
                    className="form-control"
                    min="1"
                    value={duration || ""}
                    onChange={e => updateFields({ duration: e.target.value })}
                    placeholder="Enter duration in days"
                />
            </div>
        </FormWrapper>
    );
}
