const baseUrl =
    process.env.NODE_ENV == "production"
        ? process.env.REACT_APP_UPLOAD_URL
        : process.env.REACT_APP_UPLOAD_URL_local;

export const mainurl = baseUrl + "/api/";

export const img_url = baseUrl + "/website/";

export const img_base_url = baseUrl;

// console.log("NODE_ENV = ", process.env.NODE_ENV);
console.log("mainurl = ", mainurl);
