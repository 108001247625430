import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import useFetchData from '../../hooks/useFetch/useFetchData';
import LoadingSm from '../../utils/Loader/LoadingSm';
import Error from '../../utils/Error/Error';
import PackageCard from '../PackageCard/PackageCard';
import { img_url } from '../../utils/mainurl/mainurl';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from 'swiper/modules';
import './PackageTab.css'

const PackageTab = ({ destinationData, tourPackagesData }) => {

    // const { data: destinationData, isLoading, isError } = useFetchData('destination', 'destination');

    const tourPackages = tourPackagesData?.data || [];

    const destinations = destinationData?.data || [];

    // const swiper = useSwiper();

    if (process.env.NODE_ENV === "development") console.log("destinations: ", destinations)

    const [activeIndex, setActiveIndex] = useState(0);

    const handleTabClick = (index) => { setActiveIndex(index); };

    // if (isError) {
    //     return <div><Error isError={isError} /></div>; // You can customize this with your own error message or component
    // }

    // if (isLoading) return <div>
    //     <LoadingSm />
    // </div>;

    return (
        <>
            <div className="package-card-tab-section paddingtopbottom">
                <img
                    src="assets/img/home1/section-vector5.png"
                    alt=""
                    className="section-vector5"
                />
                <img
                    src="assets/img/home1/section-vector6.png"
                    alt=""
                    className="section-vector6"
                />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb-60">
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={15}
                                        height={16}
                                        viewBox="0 0 15 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M1.92556 7.69046C2.35744 7.63298 2.78906 7.57563 3.21925 7.51077C4.14925 7.37065 5.08588 7.29138 6.01763 7.21249L6.01888 7.21243C6.15888 7.20055 6.29875 7.18874 6.43844 7.17668C7.50663 6.968 8.58732 6.89083 9.66644 6.94628C10.7733 7.06837 11.8592 7.41421 12.8857 7.97163L12.8857 8.23655C11.8592 8.79397 10.7733 9.13981 9.66644 9.26191C8.58732 9.31735 7.50663 9.24018 6.43844 9.03151C5.36831 8.93932 4.29813 8.82412 3.21925 8.69742C2.14031 8.57065 1.07012 8.42092 -6.78702e-07 8.23655L-7.01862e-07 7.97163C0.639938 7.86135 1.28306 7.77588 1.92556 7.69046ZM10.7633 15.8502C10.9332 15.4596 11.12 15.0855 11.3061 14.7127C11.389 14.5468 11.4717 14.3811 11.5527 14.2144C11.8159 13.6729 12.1141 13.1545 12.4299 12.6477C12.5448 12.4632 12.64 12.2604 12.7336 12.061C12.8972 11.7124 13.056 11.3741 13.3071 11.1616C13.7816 10.7768 14.3283 10.5734 14.886 10.574L15 10.7353C14.9945 11.4677 14.8235 12.1813 14.5088 12.7859C14.3311 13.1802 14.0336 13.4059 13.7358 13.6317C13.6073 13.7292 13.4787 13.8268 13.3597 13.9379C12.965 14.3066 12.5615 14.6637 12.1492 15.0093C11.7369 15.3549 11.3159 15.689 10.8685 16L10.7633 15.8502ZM11.7543 0.665536C11.4882 0.436859 11.2226 0.208798 10.9388 -1.5523e-06L10.816 0.149784C11.0528 0.725784 11.3072 1.27877 11.5703 1.82018C11.8335 2.3616 12.1142 2.89157 12.3949 3.40997C12.4795 3.56628 12.5538 3.73514 12.628 3.90394C12.8 4.29501 12.9718 4.68572 13.2721 4.91908C13.7312 5.33563 14.2754 5.56049 14.8334 5.56418L14.9562 5.4144C14.9651 4.68055 14.8095 3.95951 14.5089 3.3408C14.3471 3.01108 14.0894 2.80252 13.824 2.58763C13.6722 2.46474 13.5178 2.33975 13.3773 2.1888C12.9914 1.77409 12.6142 1.3824 12.1931 1.0368C12.0446 0.91489 11.8994 0.790152 11.7543 0.665536Z"
                                        />
                                    </svg>
                                    Tour Experience
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={15}
                                        height={16}
                                        viewBox="0 0 15 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M13.0744 8.30954C12.6426 8.36702 12.2109 8.42437 11.7807 8.48923C10.8507 8.62935 9.91412 8.70862 8.98237 8.78751L8.98112 8.78757C8.84112 8.79945 8.70125 8.81126 8.56156 8.82332C7.49337 9.032 6.41268 9.10917 5.33356 9.05372C4.22669 8.93163 3.14081 8.58578 2.11432 8.02837V7.76345C3.14081 7.20603 4.22669 6.86018 5.33356 6.73809C6.41268 6.68265 7.49337 6.75982 8.56156 6.96849C9.63169 7.06068 10.7019 7.17588 11.7807 7.30259C12.8597 7.42935 13.9299 7.57908 15 7.76345V8.02837C14.3601 8.13865 13.7169 8.22412 13.0744 8.30954ZM4.23673 0.14976C4.06677 0.540388 3.88 0.914468 3.69388 1.28726C3.61104 1.45317 3.52831 1.61887 3.44728 1.78561C3.18413 2.32705 2.88589 2.84546 2.57011 3.35234C2.45519 3.5368 2.36002 3.73958 2.26642 3.939C2.10282 4.28757 1.94402 4.62593 1.69294 4.83843C1.21844 5.2232 0.671682 5.42665 0.114031 5.42597L0 5.26468C0.00551875 4.53235 0.176481 3.81866 0.491219 3.2141C0.6689 2.81982 0.966407 2.59414 1.26418 2.36828C1.39271 2.27078 1.52129 2.17324 1.64031 2.06209C2.03504 1.69345 2.43853 1.33633 2.8508 0.990726C3.26307 0.645126 3.68411 0.31104 4.13147 0L4.23673 0.14976ZM3.24568 15.3345C3.51184 15.5631 3.77735 15.7912 4.06123 16L4.18404 15.8502C3.9472 15.2742 3.69281 14.7212 3.42966 14.1798C3.16651 13.6384 2.88581 13.1084 2.60511 12.59C2.52048 12.4337 2.44621 12.2649 2.37198 12.0961C2.19999 11.705 2.02816 11.3143 1.72794 11.0809C1.26879 10.6644 0.7246 10.4395 0.166563 10.4358L0.0437562 10.5856C0.0348937 11.3194 0.190456 12.0405 0.491113 12.6592C0.652919 12.9889 0.910556 13.1975 1.17597 13.4124C1.32782 13.5353 1.48222 13.6602 1.62268 13.8112C2.00863 14.2259 2.38582 14.6176 2.80686 14.9632C2.95538 15.0851 3.10063 15.2098 3.24568 15.3345Z"
                                        />
                                    </svg>
                                </span>
                                <h2>Elite Tourist Attractions</h2>
                            </div>
                        </div>
                    </div>
                    {/* <Tabs>
                        <TabList>
                            <Tab>Mario</Tab>
                            <Tab disabled>Luigi</Tab>
                            <Tab>Peach</Tab>
                            <Tab>Yoshi</Tab>
                            <Tab>Toad</Tab>
                        </TabList>

                        <TabPanel>
                            <p>
                                <b>Mario</b> (<i>Japanese: マリオ Hepburn: Mario, [ma.ɾʲi.o]</i>) (<i>English:
                                    /ˈmɑːrioʊ/; Italian: [ˈmaːrjo]</i>) is a fictional character in the Mario video
                                game franchise, owned by Nintendo and created by Japanese video game designer
                                Shigeru Miyamoto. Serving as the company's mascot and the eponymous protagonist
                                of the series, Mario has appeared in over 200 video games since his creation.
                                Depicted as a short, pudgy, Italian plumber who resides in the Mushroom
                                Kingdom, his adventures generally center upon rescuing Princess Peach from the
                                Koopa villain Bowser. His younger brother and sidekick is Luigi.
                            </p>
                            <p>
                                Source:{' '}
                                <a href="https://en.wikipedia.org/wiki/Mario" target="_blank">
                                    Wikipedia
                                </a>
                            </p>
                        </TabPanel>
                        <TabPanel>
                            <p>
                                <b>Luigi</b> (<i>Japanese: ルイージ Hepburn: Ruīji, [ɾɯ.iː.dʑi̥]</i>) (<i>English: /luˈiːdʒi/;
                                    Italian: [luˈiːdʒi]</i>) is a fictional character featured in video games and related media
                                released by Nintendo. Created by prominent game designer Shigeru Miyamoto, Luigi is portrayed
                                as the slightly younger but taller fraternal twin brother of Nintendo's mascot Mario, and
                                appears in many games throughout the Mario franchise, often as a sidekick to his brother.
                            </p>
                            <p>
                                Source:{' '}
                                <a href="https://en.wikipedia.org/wiki/Luigi" target="_blank">
                                    Wikipedia
                                </a>
                            </p>
                        </TabPanel>
                        <TabPanel>
                            <p>
                                <b>Princess Peach</b> (<i>Japanese: ピーチ姫 Hepburn: Pīchi-hime, [piː.tɕi̥ çi̥.me]</i>)
                                is a character in Nintendo's Mario franchise. Originally created by Shigeru Miyamoto,
                                Peach is the princess of the fictional Mushroom Kingdom, which is constantly under
                                attack by Bowser. She often plays the damsel in distress role within the series and
                                is the lead female. She is often portrayed as Mario's love interest and has appeared
                                in Super Princess Peach, where she is the main playable character.
                            </p>
                            <p>
                                Source:{' '}
                                <a href="https://en.wikipedia.org/wiki/Princess_Peach" target="_blank">
                                    Wikipedia
                                </a>
                            </p>
                        </TabPanel>
                        <TabPanel>
                            <p>
                                <b>Yoshi</b> (<i>ヨッシー Yosshī, [joɕ.ɕiː]</i>) (<i>English: /ˈjoʊʃi/ or /ˈjɒʃi/</i>), once
                                romanized as Yossy, is a fictional anthropomorphic dinosaur who appears in
                                video games published by Nintendo. Yoshi debuted in Super Mario World (1990) on the
                                Super Nintendo Entertainment System as Mario and Luigi's sidekick. Yoshi later starred
                                in platform and puzzle games, including Super Mario World 2: Yoshi's Island, Yoshi's Story
                                and Yoshi's Woolly World. Yoshi also appears in many of the Mario spin-off games, including
                                Mario Party and Mario Kart, various Mario sports games, and Nintendo's crossover fighting
                                game series Super Smash Bros. Yoshi belongs to the species of the same name, which is
                                characterized by their variety of colors.
                            </p>
                            <p>
                                Source:{' '}
                                <a href="https://en.wikipedia.org/wiki/Yoshi" target="_blank">
                                    Wikipedia
                                </a>
                            </p>
                        </TabPanel>
                        <TabPanel>
                            <p>
                                <b>Toad</b> (<i>Japanese: キノピオ Hepburn: Kinopio</i>) is a fictional character who primarily
                                appears in Nintendo's Mario franchise. Created by Japanese video game designer Shigeru Miyamoto,
                                appears in Nintendo's Mario franchise. Created by Japanese video game designer Shigeru Miyamoto,
                                he is portrayed as a citizen of the Mushroom Kingdom and is one of Princess Peach's most loyal
                                he is portrayed as a citizen of the Mushroom Kingdom and is one of Princess Peach's most loyal
                                attendants; constantly working on her behalf. He is usually seen as a non-player character (NPC)
                                who provides assistance to Mario and his friends in most games, but there are times when Toad(s)
                                takes center stage and appears as a protagonist, as seen in Super Mario Bros. 2, Wario's Woods,
                                Super Mario 3D World, and Captain Toad: Treasure Tracker.
                            </p>
                            <p>
                                Source:{' '}
                                <a href="https://en.wikipedia.org/wiki/Toad_(Nintendo)" target="_blank">
                                    Wikipedia
                                </a>
                            </p>
                        </TabPanel>
                    </Tabs> */}

                    <div className="package-card-with-tab lg-tab" >
                        <Tabs>
                            {/* <ul className="nav nav-pills" id="pills-tab4" role="tablist"></ul> */}

                            <TabList
                                style={{
                                    // border: '1px solid red',
                                    // overflow: 'auto'
                                }}
                                className='nav nav-pills tabstyle-packagetab'
                            >
                                {
                                    destinations?.slice(0, 5).map((destinationOne, index) => <>
                                        <Tab className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${index === activeIndex ? ` active ` : ``} `}
                                                // className="nav-link "
                                                id="egypt-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#egypt"
                                                type="button"
                                                role="tab"
                                                aria-controls="egypt"
                                                aria-selected="true"
                                                style={{
                                                    backgroundImage: index === activeIndex ? `url(${img_url}${destinationOne?.country_img})` : `none`,
                                                    objectFit: "scale-down",
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                }}
                                                onClick={() => handleTabClick(index)}
                                            >
                                                {/* <button> */}
                                                <div className={`${index === activeIndex ? ` activeTab ` : ``} `}></div>
                                                <span className={`${index === activeIndex ? ` activeTab-content ` : ``} `}>

                                                    {destinationOne?.country_name}
                                                </span>
                                            </button>
                                        </Tab>
                                    </>)
                                }
                            </TabList>

                            {
                                destinations?.slice(0, 5).map((destinationOne, index) => (
                                    <TabPanel key={index}>
                                        <div className="swiper package-card-tab-slider">
                                            <Swiper
                                                // slidesPerView={1}
                                                spaceBetween={30}
                                                modules={[Autoplay, Navigation]}
                                                navigation={{
                                                    prevEl: ".destination-card2-prev",
                                                    nextEl: ".destination-card2-next",
                                                }}
                                                breakpoints={{
                                                    320: { slidesPerView: 1, spaceBetween: 0 },
                                                    640: { slidesPerView: 2, spaceBetween: 10 },
                                                    1024: { slidesPerView: 3, spaceBetween: 10 }
                                                }}
                                                className="mySwiper"
                                            >
                                                {tourPackages && tourPackages
                                                    ?.filter((pkg) => pkg?.destination_id === destinationOne?.id)
                                                    .length > 0 ? (
                                                    tourPackages
                                                        ?.filter((pkg) => pkg?.destination_id === destinationOne?.id)
                                                        ?.map((pkg, index) => (
                                                            <div key={index} className="col-lg-4 col-md-6 col-1">
                                                                <SwiperSlide key={index}>
                                                                    <div className="swiper-slide">
                                                                        <PackageCard pkg={pkg} />
                                                                    </div>
                                                                </SwiperSlide>
                                                            </div>
                                                        ))
                                                ) : (
                                                    <p>No data available.</p>
                                                )
                                                }
                                            </Swiper>
                                        </div>
                                    </TabPanel>

                                    // <TabPanel key={index}>
                                    //     <div className="swiper package-card-tab-slider">
                                    //         {/* <div className="swiper-wrapper"> */}
                                    //             <Swiper
                                    //                 slidesPerView={3}
                                    //                 spaceBetween={30}
                                    //                 modules={[Autoplay, Navigation]}
                                    //                 navigation={{
                                    //                     prevEl: ".destination-card2-prev",
                                    //                     nextEl: ".destination-card2-next",
                                    //                 }}
                                    //                 // breakpoints={{
                                    //                 //     320: { slidesPerView: 1, spaceBetween: 10 },
                                    //                 //     640: { slidesPerView: 2, spaceBetween: 20 },
                                    //                 //     1024: { slidesPerView: 4, spaceBetween: 30 }
                                    //                 // }}
                                    //                 // loop={true}
                                    //                 className="mySwiper">
                                    //                 {
                                    //                     // console.log("tourPackagesData", tourPackages)
                                    //                     tourPackages && tourPackages
                                    //                         ?.filter((pkg) => pkg?.destination_id == destinationOne?.id)
                                    //                         ?.map((pkg, index) =>
                                    //                             <div key={index} className="col-lg-4 col-md-6">
                                    //                                 <SwiperSlide key={index}>
                                    //                                     <div className="swiper-slide">
                                    //                                         <PackageCard pkg={pkg} />
                                    //                                     </div>

                                    //                                 </SwiperSlide>
                                    //                             </div>)
                                    //                 }
                                    //             </Swiper>
                                    //         {/* </div> */}
                                    //     </div>

                                    // </TabPanel>
                                ))
                            }


                        </Tabs>


                        {/* <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="egypt-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#egypt"
                                    type="button"
                                    role="tab"
                                    aria-controls="egypt"
                                    aria-selected="true"
                                >
                                    Egypt
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="indonesia-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#indonesia"
                                    type="button"
                                    role="tab"
                                    aria-controls="indonesia"
                                    aria-selected="false"
                                >
                                    Indonesia
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="thailand-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#thailand"
                                    type="button"
                                    role="tab"
                                    aria-controls="thailand"
                                    aria-selected="false"
                                >
                                    Thailand
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="newyork-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#newyork"
                                    type="button"
                                    role="tab"
                                    aria-controls="newyork"
                                    aria-selected="false"
                                >
                                    New York
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="france-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#france"
                                    type="button"
                                    role="tab"
                                    aria-controls="france"
                                    aria-selected="false"
                                >
                                    France
                                </button>
                            </li> */}

                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="tab-content" id="pills-tab4Content">
                                    <div className="tab-pane fade show active" id="egypt" role="tabpanel">
                                        <div className="swiper package-card-tab-slider">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img7.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">3 Days / 4 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Nepal</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Indonesia Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Mecca</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Doha</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img8.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img9.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img1.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">8 Days / 9 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Turkey</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Hungary Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img3.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img5.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-50">
                                            <div className="col-lg-12">
                                                <div className="slider-btn-grp2">
                                                    <div className="slider-btn package-card-tab-prev">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                        >
                                                            <path d="M8.83399 0.281832L8.72217 0.16683L0.500652 8.50016L8.72217 16.8335L8.83398 16.7185L8.83398 13.0602L4.33681 8.50016L8.83399 3.94016L8.83399 0.281832Z" />
                                                        </svg>
                                                    </div>
                                                    <a href="package-grid.html" className="secondary-btn1">
                                                        View All Package
                                                    </a>
                                                    <div className="slider-btn package-card-tab-next">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                            fill="none"
                                                        >
                                                            <path d="M0.166016 16.7182L0.277828 16.8332L8.49935 8.49984L0.277828 0.166504L0.166016 0.281504V3.93984L4.66319 8.49984L0.166016 13.0598V16.7182Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="indonesia" role="tabpanel">
                                        <div className="swiper package-card-tab-slider">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img7.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">3 Days / 4 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Nepal</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Indonesia Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Mecca</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Doha</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img8.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img9.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img1.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">8 Days / 9 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Turkey</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Hungary Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img3.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img5.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-50">
                                            <div className="col-lg-12">
                                                <div className="slider-btn-grp2">
                                                    <div className="slider-btn package-card-tab-prev">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                        >
                                                            <path d="M8.83399 0.281832L8.72217 0.16683L0.500652 8.50016L8.72217 16.8335L8.83398 16.7185L8.83398 13.0602L4.33681 8.50016L8.83399 3.94016L8.83399 0.281832Z" />
                                                        </svg>
                                                    </div>
                                                    <a href="package-grid.html" className="secondary-btn1">
                                                        View All Package
                                                    </a>
                                                    <div className="slider-btn package-card-tab-next">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                            fill="none"
                                                        >
                                                            <path d="M0.166016 16.7182L0.277828 16.8332L8.49935 8.49984L0.277828 0.166504L0.166016 0.281504V3.93984L4.66319 8.49984L0.166016 13.0598V16.7182Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="thailand" role="tabpanel">
                                        <div className="swiper package-card-tab-slider">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img7.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">3 Days / 4 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Nepal</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Indonesia Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Mecca</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Doha</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img8.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img9.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img1.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">8 Days / 9 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Turkey</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Hungary Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img3.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img5.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-50">
                                            <div className="col-lg-12">
                                                <div className="slider-btn-grp2">
                                                    <div className="slider-btn package-card-tab-prev">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                        >
                                                            <path d="M8.83399 0.281832L8.72217 0.16683L0.500652 8.50016L8.72217 16.8335L8.83398 16.7185L8.83398 13.0602L4.33681 8.50016L8.83399 3.94016L8.83399 0.281832Z" />
                                                        </svg>
                                                    </div>
                                                    <a href="package-grid.html" className="secondary-btn1">
                                                        View All Package
                                                    </a>
                                                    <div className="slider-btn package-card-tab-next">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                            fill="none"
                                                        >
                                                            <path d="M0.166016 16.7182L0.277828 16.8332L8.49935 8.49984L0.277828 0.166504L0.166016 0.281504V3.93984L4.66319 8.49984L0.166016 13.0598V16.7182Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="newyork" role="tabpanel">
                                        <div className="swiper package-card-tab-slider">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img7.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">3 Days / 4 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Nepal</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Indonesia Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Mecca</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Doha</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img8.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img9.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img1.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">8 Days / 9 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Turkey</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Hungary Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img3.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img5.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-50">
                                            <div className="col-lg-12">
                                                <div className="slider-btn-grp2">
                                                    <div className="slider-btn package-card-tab-prev">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                        >
                                                            <path d="M8.83399 0.281832L8.72217 0.16683L0.500652 8.50016L8.72217 16.8335L8.83398 16.7185L8.83398 13.0602L4.33681 8.50016L8.83399 3.94016L8.83399 0.281832Z" />
                                                        </svg>
                                                    </div>
                                                    <a href="package-grid.html" className="secondary-btn1">
                                                        View All Package
                                                    </a>
                                                    <div className="slider-btn package-card-tab-next">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                            fill="none"
                                                        >
                                                            <path d="M0.166016 16.7182L0.277828 16.8332L8.49935 8.49984L0.277828 0.166504L0.166016 0.281504V3.93984L4.66319 8.49984L0.166016 13.0598V16.7182Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="france" role="tabpanel">
                                        <div className="swiper package-card-tab-slider">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img7.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">3 Days / 4 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Nepal</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Indonesia Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Mecca</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Doha</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img8.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img9.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img1.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">8 Days / 9 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Turkey</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Hungary Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        A Journey through Historical and Culture.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$8,629</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img3.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">6 Days / 7 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Italy</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">France Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Exploring then Spiritual and Mystical Travel.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>
                                                                        $2,872 <del>$3000</del>
                                                                    </span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="package-card">
                                                        <div className="package-card-img-wrap">
                                                            <a href="package-details.html" className="card-img">
                                                                <img
                                                                    src="assets/img/home1/package-card-img5.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <div className="batch">
                                                                <span className="date">5 Days / 6 Night</span>
                                                                <div className="location">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                    >
                                                                        <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z" />
                                                                    </svg>
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Thailand</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Japan Tour</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="package-card-content">
                                                            <div className="card-content-top">
                                                                <h5>
                                                                    <a href="package-details.html">
                                                                        Hiking, Rafting, and Exploring Travels Outdoors.
                                                                    </a>
                                                                </h5>
                                                                <div className="location-area">
                                                                    <ul className="location-list">
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Medina</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Riyadh</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Cairo</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Wakrah</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="package-grid.html">Al Rayyan</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card-content-bottom">
                                                                <div className="price-area">
                                                                    <h6>Starting Form:</h6>
                                                                    <span>$4,573</span>
                                                                    <p>TAXES INCL/PERS</p>
                                                                </div>
                                                                <a
                                                                    href="package-details.html"
                                                                    className="primary-btn2"
                                                                >
                                                                    Book a Trip
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                        viewBox="0 0 18 18"
                                                                        fill="none"
                                                                    >
                                                                        <path d="M8.15624 10.2261L7.70276 12.3534L5.60722 18L6.85097 17.7928L12.6612 10.1948C13.4812 10.1662 14.2764 10.1222 14.9674 10.054C18.1643 9.73783 17.9985 8.99997 17.9985 8.99997C17.9985 8.99997 18.1643 8.26211 14.9674 7.94594C14.2764 7.87745 13.4811 7.8335 12.6611 7.80518L6.851 0.206972L5.60722 -5.41705e-07L7.70276 5.64663L8.15624 7.77386C7.0917 7.78979 6.37132 7.81403 6.37132 7.81403C6.37132 7.81403 4.90278 7.84793 2.63059 8.35988L0.778036 5.79016L0.000253424 5.79016L0.554115 8.91458C0.454429 8.94514 0.454429 9.05483 0.554115 9.08539L0.000253144 12.2098L0.778036 12.2098L2.63059 9.64035C4.90278 10.1523 6.37132 10.1857 6.37132 10.1857C6.37132 10.1857 7.0917 10.2102 8.15624 10.2261Z" />
                                                                        <path d="M12.0703 11.9318L12.0703 12.7706L8.97041 12.7706L8.97041 11.9318L12.0703 11.9318ZM12.0703 5.23292L12.0703 6.0714L8.97059 6.0714L8.97059 5.23292L12.0703 5.23292ZM9.97892 14.7465L9.97892 15.585L7.11389 15.585L7.11389 14.7465L9.97892 14.7465ZM9.97892 2.41846L9.97892 3.2572L7.11389 3.2572L7.11389 2.41846L9.97892 2.41846Z" />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-50">
                                            <div className="col-lg-12">
                                                <div className="slider-btn-grp2">
                                                    <div className="slider-btn package-card-tab-prev">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                        >
                                                            <path d="M8.83399 0.281832L8.72217 0.16683L0.500652 8.50016L8.72217 16.8335L8.83398 16.7185L8.83398 13.0602L4.33681 8.50016L8.83399 3.94016L8.83399 0.281832Z" />
                                                        </svg>
                                                    </div>
                                                    <a href="package-grid.html" className="secondary-btn1">
                                                        View All Package
                                                    </a>
                                                    <div className="slider-btn package-card-tab-next">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={9}
                                                            height={17}
                                                            viewBox="0 0 9 17"
                                                            fill="none"
                                                        >
                                                            <path d="M0.166016 16.7182L0.277828 16.8332L8.49935 8.49984L0.277828 0.166504L0.166016 0.281504V3.93984L4.66319 8.49984L0.166016 13.0598V16.7182Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
        </>
    );

    return (
        <>
            <h1>trex</h1>
            <Tabs>
                <TabList>
                    <Tab>Title 1</Tab>
                    <Tab>Title 2</Tab>
                </TabList>

                <TabPanel>
                    <h2>Any content 1</h2>
                </TabPanel>
                <TabPanel>
                    <h2>Any content 2</h2>
                </TabPanel>
            </Tabs>
        </>
    )
};



// const PackageTabSm = ({tourPackages, destinations}) => {
//     return (
//         <div className="package-card-with-tab sm-tab">
//             <Tabs>
//                 <ul className="nav nav-pills" id="pills-tab4" role="tablist">
//                     <TabList
//                         style={{
//                             // border: '1px solid red',
//                             // overflow: 'auto'
//                         }}
//                     // className='tabstyle-packagetab'
//                     >
//                         {
//                             destinations?.slice(0, 5).map((destinationOne, index) => <>
//                                 <Tab className="nav-item" role="presentation">
//                                     <button
//                                         className={`nav-link ${index === activeIndex ? ` active ` : ``} `}
//                                         // className="nav-link "
//                                         id="egypt-tab"
//                                         data-bs-toggle="pill"
//                                         data-bs-target="#egypt"
//                                         type="button"
//                                         role="tab"
//                                         aria-controls="egypt"
//                                         aria-selected="true"
//                                         style={{
//                                             backgroundImage: index === activeIndex ? `url(${img_url}${destinationOne?.country_img})` : `none`,
//                                             objectFit: "scale-down",
//                                             width: '100%',
//                                             height: '100%',
//                                             backgroundSize: 'cover',
//                                             backgroundPosition: 'center',
//                                         }}
//                                         onClick={() => handleTabClick(index)}
//                                     >
//                                         {/* <button> */}
//                                         <div className={`${index === activeIndex ? ` activeTab ` : ``} `}></div>
//                                         <span className={`${index === activeIndex ? ` activeTab-content ` : ``} `}>

//                                             {destinationOne?.country_name}
//                                         </span>
//                                     </button>
//                                 </Tab>
//                             </>)
//                         }
//                     </TabList>
//                 </ul>
//                 {
//                     destinations?.slice(0, 5).map((destinationOne, index) => (
//                         <TabPanel key={index}>
//                             <div className="swiper package-card-tab-slider">
//                                 <Swiper
//                                     // slidesPerView={1}
//                                     spaceBetween={30}
//                                     modules={[Autoplay, Navigation]}
//                                     navigation={{
//                                         prevEl: ".destination-card2-prev",
//                                         nextEl: ".destination-card2-next",
//                                     }}
//                                     breakpoints={{
//                                         320: { slidesPerView: 1, spaceBetween: 0 },
//                                         640: { slidesPerView: 2, spaceBetween: 10 },
//                                         1024: { slidesPerView: 3, spaceBetween: 10 }
//                                     }}
//                                     className="mySwiper"
//                                 >
//                                     {tourPackages && tourPackages
//                                         ?.filter((pkg) => pkg?.destination_id === destinationOne?.id)
//                                         .length > 0 ? (
//                                         tourPackages
//                                             ?.filter((pkg) => pkg?.destination_id === destinationOne?.id)
//                                             ?.map((pkg, index) => (
//                                                 <div key={index} className="col-lg-4 col-md-6 col-1">
//                                                     <SwiperSlide key={index}>
//                                                         <div className="swiper-slide">
//                                                             <PackageCard pkg={pkg} />
//                                                         </div>
//                                                     </SwiperSlide>
//                                                 </div>
//                                             ))
//                                     ) : (
//                                         <p>No data available.</p>
//                                     )
//                                     }
//                                 </Swiper>
//                             </div>
//                         </TabPanel>

//                         // <TabPanel key={index}>
//                         //     <div className="swiper package-card-tab-slider">
//                         //         {/* <div className="swiper-wrapper"> */}
//                         //             <Swiper
//                         //                 slidesPerView={3}
//                         //                 spaceBetween={30}
//                         //                 modules={[Autoplay, Navigation]}
//                         //                 navigation={{
//                         //                     prevEl: ".destination-card2-prev",
//                         //                     nextEl: ".destination-card2-next",
//                         //                 }}
//                         //                 // breakpoints={{
//                         //                 //     320: { slidesPerView: 1, spaceBetween: 10 },
//                         //                 //     640: { slidesPerView: 2, spaceBetween: 20 },
//                         //                 //     1024: { slidesPerView: 4, spaceBetween: 30 }
//                         //                 // }}
//                         //                 // loop={true}
//                         //                 className="mySwiper">
//                         //                 {
//                         //                     // console.log("tourPackagesData", tourPackages)
//                         //                     tourPackages && tourPackages
//                         //                         ?.filter((pkg) => pkg?.destination_id == destinationOne?.id)
//                         //                         ?.map((pkg, index) =>
//                         //                             <div key={index} className="col-lg-4 col-md-6">
//                         //                                 <SwiperSlide key={index}>
//                         //                                     <div className="swiper-slide">
//                         //                                         <PackageCard pkg={pkg} />
//                         //                                     </div>

//                         //                                 </SwiperSlide>
//                         //                             </div>)
//                         //                 }
//                         //             </Swiper>
//                         //         {/* </div> */}
//                         //     </div>

//                         // </TabPanel>
//                     ))
//                 }
//             </Tabs>
//         </div>
//     );
// };


export default PackageTab;