import React, { useEffect, useState } from "react";
import { FormWrapper } from "./FormWrapper";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export function BookingDateForm({
    bookingDate,
    updateFields,
}) {
    const [startDate, setStartDate] = useState(new Date());
    // onChange={(e) => updateFields({ name: e.target.value })}
    
    useEffect(() => {
        updateFields({ bookingDate: startDate });
    }, [startDate, updateFields]);
    // bookingDate = startDate
    return (
        <FormWrapper title="Approximately Booking Date">
            <DatePicker className="form-group border" selected={startDate} onChange={(date) => setStartDate(date)} />

        </FormWrapper>
    );
}
