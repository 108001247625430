import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "./DashboardLayout.scss";
import NavbarModule from "../Navbar/NavbarModule";
import Footer from "../Footer/Footer";

const DashboardLayout = () => {
  return (
    <div className="boxed_wrapper ltr">
      {/* <NavbarModule /> */}
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
};

export default DashboardLayout;
