import React from 'react';
import { Link } from 'react-router-dom';
import { img_url } from '../../utils/mainurl/mainurl';

const DestinationCard = ({ destinationOne }) => {
    // console.log(`url(${img_url}${bannerData?.image})`)
    return (
        <>
            <div className="swiper-slide">
                <div className="destination-card2">
                    <Link
                        to={`/destination/${destinationOne?.id}`}
                        className="destination-card-img"
                    >
                        <img
                            src={`${img_url}${destinationOne?.country_img}`}
                            alt="Destination"
                            style={{
                                maxWidth: '258px',
                                maxHeight: '190px', // lg Screen
                                minWidth: '100%',
                                minHeight: '100%', // lg Screen
                                // width: '372px', height: '258px', // mobile
                                objectFit: 'cover'
                                // Ensures the image covers the entire area without distortion 
                            }}
                        />
                    </Link>
                    <div className="batch">
                        <span>{ } Tour</span>
                    </div>
                    <div className="destination-card2-content">
                        <span>Travel To</span>
                        <h4 style={{ textDecoration: 'none' }}>
                            <Link to={`destination/${destinationOne?.id}`} style={{ textDecoration: 'none' }}>{destinationOne?.country_name}</Link>
                        </h4>
                    </div>
                </div>
            </div>
        </>
    );
};


export default DestinationCard;