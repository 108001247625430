import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NavbarModule from '../../Navbar/NavbarModule';
import { Outlet } from 'react-router-dom';
import Footer from '../../Footer/Footer';
// import { Helmet } from 'react-helmet-async';
import DynamicHelmet from '../../../utils/DynamicHelmet/DynamicHelmet';
import Error from '../../../utils/Error/Error';
import Loading from '../../../utils/Loader/Loading';
import useFetchData from '../../../hooks/useFetch/useFetchData';
import LoadingSm from '../../../utils/Loader/LoadingSm';
import { img_url } from '../../../utils/mainurl/mainurl';
import './MainLayout.css'
import MultistepForm from '../../MultistepForm/MultistepForm';
import PopupForm from '../../PopupForm/PopupForm';

const MainLayout = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // console.log("useEffect ", isModalOpen)
    useEffect(() => {
        // Show the modal when the component mounts
        // setIsModalOpen(true);
        handleShow();
    }, []);

    const { data: welcome_messages, isLoading: isLoadingWelcome_messages, isError: isErrorBrand_welcome_messages } = useFetchData(
        'welcome_messages',
        `welcome_messages/active`
    );
    const { data: brandRes, isLoading: isLoadingBrandRes, isError: isErrorBrand } = useFetchData(
        'footer',
        `footer`
    );

    const { data: menuRes, isLoading: isLoadingMenu, isError: isErrorMenu } = useFetchData(
        'menu',
        `menu-group/active/menu-item/website`
    );

    if (isLoadingWelcome_messages || isLoadingBrandRes || isLoadingMenu) return <div className=""><Loading /></div>;

    if (isErrorBrand_welcome_messages) return <div> <Error isError={isErrorBrand_welcome_messages} /> </div>;
    if (isErrorBrand) return <div> <Error isError={isErrorBrand} /> </div>;
    if (isErrorMenu) return <div> <Error isError={isErrorMenu} /> </div>;

    return (
        <div className="boxed_wrapper ltr layout-relative">
            {/* <Helmet>
                <link
                    rel="icon"
                    // type="image/png"
                    href={`${img_url}${brand?.logo}`}
                />
                {
                    console.log(`${img_url}${brand?.logo}`)
                }
            </Helmet> */}
            {/* <DynamicHelmet /> */}

            {/* {isModalOpen && <PopupForm />} */}
            <PopupForm show={show} handleClose={handleClose} />

            <NavbarModule
                welcome_messages={welcome_messages}
                brandRes={brandRes}
                menuRes={menuRes}
            />
            <Outlet />
            <Footer />
        </div>
    );
};

// function PopupForm({ show, handleClose }) {

//     const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
//         useMultistepForm([
//             <UserForm {...data} updateFields={updateFields} />,
//             <AddressForm {...data} updateFields={updateFields} />,
//             <AccountForm {...data} updateFields={updateFields} />,
//         ])

//     return (
//         <>
//             {/* <Button variant="primary" onClick={handleShow}>
//                 Launch static backdrop modal
//             </Button> */}

//             <Modal
//                 show={show}
//                 onHide={handleClose}
//                 backdrop="static"
//                 keyboard={false}
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>Modal title</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {/* I will not close if you click outside me. Do not even try to press
//                     escape key. */}
//                     <MultistepForm />
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>
//                         Close
//                     </Button>
//                     <Button variant="primary">Understood</Button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// }

export const Popupcustom = ({ setIsModalOpen }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 modalContainer">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm modalForm">
                <h2 className="text-xl font-semibold">Welcome to Our Website!</h2>
                <p className="mt-2 text-gray-600">Enjoy exploring our content.</p>

                <button
                    className="btn btn-error mt-4 w-full"
                    onClick={() => setIsModalOpen(false)}
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default MainLayout;