import React from 'react';
import Loading from '../../utils/Loader/Loading';
import useFetchData from '../../hooks/useFetch/useFetchData';
import Error from '../../utils/Error/Error';
import { Link } from 'react-router-dom';
import VisaCard from '../../components/VisaCard/VisaCard';
import Subscribe from '../../components/Subscribe/Subscribe';
import OfferCard from '../../components/OfferCard/OfferCard';

const OfferPage = () => {

    const { data, isLoading, isError } = useFetchData('offer', 'offer/active');

    const offers = data?.data || [];

    console.log("offer: ", offers)

    if (isError) {
        return <div><Error isError={isError} /></div>;
    }

    if (isLoading) return <div>
        <Loading />
    </div>;

    return (
        <div>
            <>
                <>
                    <div
                        className="breadcrumb-section"
                        style={{
                            backgroundImage:
                                "linear-gradient(270deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0.3) 101.02%), url(../../assets/img/innerpage/inner-banner-bg.png)"
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 d-flex justify-content-center">
                                    <div className="banner-content">
                                        <h1>Offers</h1>
                                        <ul className="breadcrumb-list">
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>Offers</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Package Grid section */}
                    <div className="package-grid-section pt-120 mb-120">
                        <div className="container">
                            <div className="row gy-5 mb-70 ">
                                {
                                    offers && offers?.map((pkg, index) => <div key={index} className="col-lg-4 col-md-6">
                                        <OfferCard one={pkg} />
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                    {/* End Package Grid section */}
                    {/* Start Banner3 section */}
                    <Subscribe />
                    {/* End Banner3 section */}
                    {/* Start Footer Section */}
                    {/* End Footer Section */}
                </>
            </>
        </div>
    );
};

export default OfferPage;