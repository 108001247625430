import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import MultistepForm from '../MultistepForm/MultistepForm';
import { useMultistepForm } from '../MultistepForm/useMultistepForm';
import { UserForm } from '../MultistepForm/UserForm';
import { TourTypeForm } from '../MultistepForm/TourTypeForm';
import { DestinationForm } from '../MultistepForm/DestinationForm';
import { TravelcategoryForm } from '../MultistepForm/TravelcategoryForm';
import { MonthPickerForm } from '../MultistepForm/MonthPickerForm';
import { DurationForm } from '../MultistepForm/DurationForm';
import { PopulationCountForm } from '../MultistepForm/PopulationCountForm';
import { WhatsappComunication } from '../MultistepForm/WhatsappComunication';
import { BookingDateForm } from '../MultistepForm/BookingDateForm';
import { BudgetForm } from '../MultistepForm/BudgetForm';
import { UserLocationForm } from '../MultistepForm/UserLocationForm';

const INITIAL_DATA = {
    tourtype: "",
    destination: "",
    travelcategory: "",
    // isDateFixed: false,
    // date: "",
    selectedMonth: "",
    duration: "",
    name: "",
    phone: "",
    email: "",
    adults: "",
    children: "",
    whatsappComunication: "",
    bookingDate: "",
    budget: "",
    userlocation: "",
}

const PopupForm = ({ show, handleClose }) => {

    // const { data: tourTypeRes, isLoadingTourType, isErrorTourType } = useFetchData('tourType', 'tourType/active');
    // const { data: destinationData, isLoading: isLoadingDestination, isError: isErrorDestination } = useFetchData('destination', 'destination');
    // const { data: travelCategoryData, isLoading: isLoadingTravelCategory, isError: isErrorTravelCategory } = useFetchData('travelCategory', 'travelCategory');

    const [data, setData] = useState(INITIAL_DATA)

    function updateFields(fields) {
        console.log("updateFields-fields", fields)
        setData(prev => {
            return { ...prev, ...fields }
        })
    }

    const { steps, currentStepIndex, step, title, isFirstStep, isLastStep, back, next } =
        useMultistepForm([
            { title: "Select Tour Type", component: <TourTypeForm {...data} updateFields={updateFields} /> },
            { title: "Select Destination", component: <DestinationForm {...data} updateFields={updateFields} /> },
            { title: "Choose Travel Category", component: <TravelcategoryForm {...data} updateFields={updateFields} /> },
            { title: "Pick a Month", component: <MonthPickerForm {...data} updateFields={updateFields} /> },
            { title: "Select Duration", component: <DurationForm {...data} updateFields={updateFields} /> },
            { title: "Your Details", component: <UserForm {...data} updateFields={updateFields} /> },
            { title: "Specify Population", component: <PopulationCountForm {...data} updateFields={updateFields} /> },
            { title: "WhatsApp Communication", component: <WhatsappComunication {...data} updateFields={updateFields} /> },
            { title: "Select Booking Date", component: <BookingDateForm {...data} updateFields={updateFields} /> },
            { title: "Budget Estimate", component: <BudgetForm {...data} updateFields={updateFields} /> },
            { title: "User Location", component: <UserLocationForm {...data} updateFields={updateFields} /> },


            // <IsDateFixedForm {...data} updateFields={updateFields} />,
            // data?.isDateFixed ? <AddressForm {...data} updateFields={updateFields} /> : <AccountForm {...data} updateFields={updateFields} />,
            // <AccountForm {...data} updateFields={updateFields} />,
        ])
    useEffect(() => {
        console.log("INITIAL_DATA", INITIAL_DATA)
    }, [INITIAL_DATA]);

    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
                Launch static backdrop modal
            </Button> */}

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* I will not close if you click outside me. Do not even try to press
                    escape key. */}
                    {/* <MultistepForm {data, steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } /> */}
                    <MultistepForm
                        data={data}
                        steps={steps}
                        currentStepIndex={currentStepIndex}
                        step={step}
                        isFirstStep={isFirstStep}
                        isLastStep={isLastStep}
                        back={back}
                        next={next}
                        handleClose={handleClose}
                    />

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}

export default PopupForm;